import DateInput from "../../../components/common/DateInput";
import FileInput from "../../../components/common/FileInput";
import { useContext, useEffect, useState } from "react";
import AddBenificialModel from "../../../components/modals/AddBeneficialModal";
import InputValuesContext from "../../../contexts/InputValuesContext";
import { ListInputItem } from "../../../components/common/ListInputItem";
import EditTableModal from "../../../components/EditTableModal";
import { extractXLSXCells } from "../../../utils/csv";
import { transpose } from "../../../utils/array";
import { isFileType } from "../../../helpers/helper";
import { AiFillDelete } from "react-icons/ai";
import { ClauseEntity, SubClauseEntity } from "../../../domain/entities";
import { Control, FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import { BeneficialEntity } from "../../../domain/entities";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { SegmentedClauseParam } from "../../../domain/types/ClauseParams";

function ParameterInput({
  param,
  clauseId,
  subClauseId,
  control,
  currentValue,
  handleInputChange,
  register,
  errors,
  isEditing,
}:
  {
    param: SegmentedClauseParam;
    clauseId: ClauseEntity['id'];
    subClauseId: SubClauseEntity['id'];
    control: Control<FieldValues, any>;
    currentValue: any;
    handleInputChange: (
      e: any,
      paramName: string,
      clauseId: ClauseEntity['id'],
      subClauseId: SubClauseEntity['id'],
    ) => void;
    register: UseFormRegister<FieldValues>;
    errors: FieldErrors<FieldValues>;
    isEditing: boolean;
  }) {
  const { t, language } = useTranslation();

  const currentTranspose = !!currentValue?.[0]
  let currentCells = param.type == "csv" ? currentValue?.[1] ?? [] : []

  currentCells = currentTranspose ? transpose(currentCells) : currentCells
  const [showBenificialModel, setShowBenificialModel] = useState(false);
  const [showTableModal, setShowTableModal] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [csvCells, setCsvCells] = useState(currentCells);
  const [calendarOpen, setCalendarOpen] = useState(false);

  const { inputValues, beneficialsMap, setBeneficialsMap, fileNames } = useContext(InputValuesContext);
  const fieldName = `${clauseId}_${subClauseId}_${param.name}`;
  useEffect(() => {
    register(fieldName, { required: true });
  }, [register, fieldName]);

  const onCSVInputChange = (file: File) => {
    if (file) {
      extractXLSXCells(file, (cells) => {
        setCsvCells(cells);
        handleInputChange(
          { target: { value: [false, cells, file.name] } },
          param.name,
          clauseId,
          subClauseId
        );
      });
    } else {
      setCsvCells([]);
      handleInputChange(
        { target: { value: null } },
        param.name,
        clauseId,
        subClauseId
      );
    }
  };
  const onTranspose = (value: boolean) => {
    console.log(value);

    handleInputChange(
      {
        target: {
          value: [
            value,
            value ? transpose(csvCells) : csvCells,
            currentValue[2],
          ],
        },
      },
      param.name,
      clauseId,
      subClauseId
    );
  };

  switch (param.type) {
    // Inside the switch block of ParameterInput function
    case 'beneficial':
      return (
        <>
          <button
            type="button"
            className="benificial-button"
            onClick={() => setShowBenificialModel(true)}
          >
            {param.label}
          </button>
          {showBenificialModel && (
            <AddBenificialModel
              onClose={() => setShowBenificialModel(false)}
              beneficialsMap={beneficialsMap}
              setBeneficialsMap={setBeneficialsMap}
              currentBenificialData={beneficialsMap[currentValue] || {}}
              onSave={(beneficialId) => {
                handleInputChange(
                  { target: { value: beneficialId } },
                  param.name,
                  clauseId,
                  subClauseId
                );
                setShowBenificialModel(false);
              }}
              beneficialTypes={param.args?.beneficialTypes ?? ['Company', 'Person']}
            />
          )}
        </>
      );
    case 'beneficial[]':
      const beneficialsId: BeneficialEntity['id'][] = Array.isArray(currentValue) && currentValue || []
      const beneficials = beneficialsId.map(id => beneficialsMap[id])

      return (
        <div
          className={`d-flex flex-column ${currentValue ? "green-border" : "grey-border"
            }`}
        >
          <label htmlFor={param.name} className="ms-2">
            {param.label}
          </label>
          {beneficialsId.length > 0 ? (
            <div className="input-done ms-2">{t("pages.editionContract.complete")}</div>
          ) : (
            <div className="input-inprogress ms-2">{t("pages.editionContract.inProgress")}</div>
          )}
          {beneficials.map((beneficial, idx) =>
            <div key={beneficial.id} className="d-flex gap-2 align-items-center grey-border">
              <div className="w-100">
                {beneficial.name}
              </div>
              <AiFillDelete
                style={{
                  marginRight: "0.5rem",
                  color: "#e5142f",
                  cursor: "pointer",
                }}
                onClick={() => {
                  let newValue = beneficialsId.filter(id => id != beneficial.id)
                  handleInputChange(
                    { target: { value: newValue } },
                    param.name,
                    clauseId,
                    subClauseId
                  );
                }}
              />
            </div>)}
          <div
          >
            <button
              type="button"
              className="benificial-button"
              onClick={() => {
                setShowBenificialModel(true);
              }}
            >
              {t("pages.editionContract.addBeneficial")}
            </button>
            {showBenificialModel && (
              <AddBenificialModel
                beneficialsMap={beneficialsMap}
                setBeneficialsMap={setBeneficialsMap}
                currentBenificialData={beneficialsMap[currentValue] || {}}
                onSave={(beneficialId) => {
                  handleInputChange(
                    { target: { value: [...beneficialsId, beneficialId] } },
                    param.name,
                    clauseId,
                    subClauseId
                  );
                  setShowBenificialModel(false);
                }}
                onClose={() => setShowBenificialModel(false)}
                beneficialTypes={param.args?.beneficialTypes ?? ['Company', 'Person']}
              />
            )}

          </div>
        </div>
      );
    case 'table':
      const currentTableValues: (string | Date | number)[][] = inputValues?.[param.name] || [];
      return (
        <>
          <button
            type="button"
            className="benificial-button"
            onClick={() => setShowTableModal(true)}
          >
            {param.label}
          </button>
          {showTableModal && (
            <EditTableModal
              param={param}
              onClose={() => setShowTableModal(false)}
              currentTableValues={currentTableValues}
              onSave={(tableData) => {
                handleInputChange(
                  { target: { value: tableData } },
                  param.name,
                  clauseId,
                  subClauseId
                );
                setShowBenificialModel(false);
              }}
            />
          )}
        </>
      );

    case 'boolean':
      return (
        <div className="d-flex flex-column  gap-2  py-2 justify-content-between checkbox-input-container align-items-center form-group">
          <div className="d-flex flex-column ">
            <label htmlFor={param.name}>{param.label}</label>
            {/* <div className="checkbox-label">{param.label}</div> */}
          </div>
          <div className="radio-group boolean-group">
            <label className="form-check-label">
              <input
                type="radio"
                value="true"
                checked={currentValue}
                className="form-check-input boolean-check"
                onChange={() => {
                  handleInputChange(
                    { target: { value: true } },
                    param.name,
                    clauseId,
                    subClauseId,
                  );
                }}
                name={param.name}
              />
              {t("pages.editionContract.yes")}
            </label>
            <label className="form-check-label">
              <input
                type="radio"
                value="false"
                checked={!currentValue}
                className="form-check-input boolean-check"
                onChange={() => {
                  handleInputChange(
                    { target: { value: false } },
                    param.name,
                    clauseId,
                    subClauseId,
                  );
                }}
                name={param.name}
              />
              {t("pages.editionContract.no")}
            </label>
          </div>
        </div>
      );
    case 'date':
      return (
        <div
          className={`d-flex flex-column ${currentValue ? "green-border" : "grey-border"
            }`}
          style={{ marginBottom: calendarOpen && "300px" }}
        >
          <label htmlFor={param.name} className="ms-1">
            {param.label ?? "JJ/MM/AAAA"}
          </label>
          <div className="d-flex gap-2 align-items-center">
            {currentValue ? (
              <div className="input-done ms-2"> {t("pages.editionContract.complete")}</div>
            ) : (
              <div className="input-inprogress ms-2">  {t("pages.editionContract.inProgress")}</div>
            )}
            <div className="w-100">
              <DateInput
                name={`${clauseId}_${subClauseId}_${param.name}`}
                // label={param.label}
                control={control}
                setCalendarOpen={setCalendarOpen}
                defaultValue={currentValue}
                onChange={(date) => {
                  if (date && !isNaN(date.getTime())) {
                    handleInputChange(
                      {
                        target: { value: date.toISOString().substring(0, 10) },
                      },
                      param.name,
                      clauseId,
                      subClauseId
                    );
                  }
                  setCalendarOpen(false)
                }}
              />
            </div>
          </div>
        </div>
      );
    case 'file':
      const displayFile = currentValue && (isFileType(currentValue) ? currentValue : { name: fileNames[currentValue] })
      const fileId = currentValue && Number(currentValue) || undefined
      return (
        <div className={`${currentValue ? "green-border" : "grey-border"}`}>
          <label htmlFor={param.name} className="ps-1">
            {param.label}
          </label>
          <div className="d-flex gap-2">
            {currentValue ? (
              <div className="input-done ms-2"> {t("pages.editionContract.complete")}</div>
            ) : (
              <div className="input-inprogress ms-2">{t("pages.editionContract.inProgress")}</div>
            )}
            <div className="w-100">
              <FileInput
                {...register(fieldName, { required: true })}
                onChange={(file) => {
                  handleInputChange({
                    target: { value: file },
                  }, param.name, clauseId, subClauseId)
                }
                }
                id={fileId}
                value={displayFile}
                borderClassName={displayFile ? "green-border" : "grey-border"}
              />
            </div>
          </div>
        </div>
      );
    case 'csv':
      return (
        <div className={`${currentValue ? "green-border" : "grey-border"}`}>
          <label htmlFor={param.name} className="ps-1">
            {param.label}
          </label>

          <div className="d-flex gap-2">
            {currentValue ? (
              <div className="input-done ms-2">{t("pages.editionContract.complete")}</div>
            ) : (
              <div className="input-inprogress ms-2">{t("pages.editionContract.inProgress")}
              </div>
            )}
            {currentValue && (
              <div className="switch w-10">
                <input
                  type="checkbox"
                  id={`toggle_${clauseId}_${subClauseId}`}
                  onChange={(e) => onTranspose(e.target.checked)}
                />
                <label htmlFor={`toggle_${clauseId}_${subClauseId}`}></label>
              </div>
            )}
            <div className="w-100">
              <FileInput
                {...register(fieldName, { required: true })}
                onChange={onCSVInputChange}
                value={currentValue ? { name: currentValue[2] } : null}
                borderClassName={currentValue ? "green-border" : "grey-border"}
              />
            </div>
          </div>
        </div>
      );

    case 'list':
      return (
        <div style={{ border: "1px solid gray", borderRadius: "4px", padding: "5px" }}>
          <label htmlFor={param.name}>{param.label}</label>
          {param.args.map((arg, index) => (
            <ListInputItem
              key={index}
              index={index}
              handleInputChange={handleInputChange}
              clauseId={clauseId}
              subClauseId={subClauseId}
              control={control}
              fieldName={fieldName}
              param={param}
              isEditing={isEditing}
            />
          ))}
        </div>
      );

    case 'enum':
      const enumOptions = param.args.map((arg) => arg.option);
      const hasValue =
        currentValue !== null &&
        currentValue !== undefined &&
        currentValue !== "";
      return (
        <>
          <div className="form-group">
            <div
              className={`d-flex flex-column  ${!hasValue ? "grey-border" : "green-border"
                }`}
            >
              <div className="d-flex align-items-start justify-content-between py-2 px-2">

                <label className="ms-2">{param.label}</label>
                {hasValue ? (
                  <div className="input-done ms-2">{t("pages.editionContract.complete")}</div>
                ) : (
                  <div className="input-inprogress ms-2">{t("pages.editionContract.inProgress")}</div>
                )}
              </div>
              <div className="radio-group flex-column align-items-start px-3 pb-1 gap-0">
                {enumOptions.map((option, index) => (
                  <div key={`${fieldName}_${index}`} className="form-check">
                    <div>
                      <input
                        type="radio"
                        className="form-check-input"
                        value={index}
                        checked={currentValue === index}
                        onChange={() => {
                          handleInputChange(
                            { target: { value: index } },
                            param.name,
                            clauseId,
                            subClauseId
                          );
                        }}
                      />
                      <label className="form-check-label" htmlFor={String(index)}>
                        {option}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      );

    default:
      return (
        <div
          className={`d-flex  ${currentValue ? "green-border" : "grey-border"
            }`}
          style={{
            boxShadow: isFocused ? "0 0 0 2px #893aee" : "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: language == "ar" ? "right" : "left",
            alignItems: "left",
            // padding: "5px",
          }}
        >
          <label htmlFor={param.name} className="ms-1">
            {param.label}
          </label>
          <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}>
            {currentValue ? (
              <div className="input-done ms-2">{t("pages.editionContract.complete")}</div>
            ) : (
              <div className="input-inprogress ms-2">{t("pages.editionContract.inProgress")}</div>
            )}
            <textarea
              value={currentValue || ""}
              {...register(fieldName, { required: true })}
              onChange={(e) =>
                handleInputChange(e, param.name, clauseId, subClauseId)
              }
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className="custom-textarea"
              placeholder={param.label}
            />
          </div>



        </div>
      );
  }
}
export default ParameterInput;
