import React, { useState, useEffect } from 'react';
import { useTranslation } from '../../contexts/TranslationProvider';

function BeneficialType({ setSelectedType, disabled, selected }: { selected: any, disabled: boolean, setSelectedType: (type: 'Person' | 'Company' | 'Minor' | 'Joint' | 'Attorney' | null) => void }) {
  const { t } = useTranslation();
  const translationPath = t("pages.contractTemplateParam.edition");
  const [selectedCheckbox, setSelectedCheckbox] = useState<'Person' | 'Company' | 'Minor' | 'Joint' | 'Attorney' | null>(null);
  useEffect(() => {
    if (selected?.args?.beneficialTypes?.[0]) {
      setSelectedCheckbox(selected.args.beneficialTypes[0]);
    }
  }, [selected]);

  const handleCheckboxChange = (type: 'Person' | 'Company' | 'Minor' | 'Joint' | 'Attorney') => {
    setSelectedCheckbox(type);
    setSelectedType(type);
  };

  return (
    <div>
      <div className="checkbox-beneficial">
        <input
          type="checkbox"
          checked={selectedCheckbox === 'Person'}
          name="Person"
          onChange={() => handleCheckboxChange('Person')}
          disabled={disabled}
        />
        <label htmlFor="Person"> {translationPath.beneficial.person} </label>
      </div>
      <div className="checkbox-beneficial">
        <input
          type="checkbox"
          checked={selectedCheckbox === 'Company'}
          name="Company"
          onChange={() => handleCheckboxChange('Company')}
          disabled={disabled}
        />
        <label htmlFor="Company"> {translationPath.beneficial.company} </label>
      </div>
      <div className="checkbox-beneficial">
        <input
          type="checkbox"
          checked={selectedCheckbox === 'Minor'}
          name="Minor"
          onChange={() => handleCheckboxChange('Minor')}
          disabled={disabled}
        />
        <label htmlFor="Minor"> {translationPath.beneficial.minor} </label>
      </div>
      <div className="checkbox-beneficial">
        <input
          type="checkbox"
          checked={selectedCheckbox === 'Joint'}
          name="Joint"
          onChange={() => handleCheckboxChange('Joint')}
          disabled={disabled}
        />
        <label htmlFor="Joint"> 
          {translationPath?.beneficial?.spouse}
           </label>
      </div>
      <div className="checkbox-beneficial">
        <input
          type="checkbox"
          checked={selectedCheckbox === 'Attorney'}
          name="Attorney"
          onChange={() => handleCheckboxChange('Attorney')}
          disabled={disabled}
        />
        <label htmlFor="Attorney"> 
          {translationPath?.beneficial?.attorney}

        </label>
      </div>
    </div>
  );
}

export default BeneficialType;
