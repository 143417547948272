import { Descendant } from 'slate'
import { RenderSegment, RenderSegments, SegmentedText, SegmentedTextType, segmentedTextStyle } from '../../../../../domain/types/ClauseParams'
import { useEffect } from "react";
import { ClauseEntity } from '../../../../../domain/entities';

export const buildBlocks = (segments, params) => {
  const newBlocks = []; // Final array of paragraphs and tables
  let currentParagraph = { type: 'paragraph', children: [] }; // Current paragraph being built
  let children = []; // Children for the current paragraph
  let currentTable = null; // Temporary store for table structure
  let currentRow = null;   // Temporary store for row structure
  let currentCell = null;  // Temporary store for cell structure
  let insideTable = false; // Flag to check if we are inside a table

  const processSegmentContent = (segment, params) => {
    if (segment.type === SegmentedTextType.STATIC) {
      const isHighlighted = segment.isHighlighted || false;
      const styles = segment.style || {};
      return {
        text: segment.value,
        id: segment.id,
        highlight: isHighlighted,
        ...styles
      };
    }

    if (segment.type === SegmentedTextType.PARAM) {
      const param = params.find(p => p.name === segment.paramName);
      const label = param?.label || '';
      const fullLabel = (param.type === 'beneficial' || param.type === 'beneficial[]')
        ? `${label}${segment.value.split('.')[1] ? ` (${segment.value.split('.')[1]})` : ''}`
        : label;
      return {
        type: 'mention',
        character: fullLabel,
        paramName: segment.value,
        id: segment.id,
        children: [{ text: segment.paramName }]
      };
    }

    if (segment.type === SegmentedTextType.COMMENT) {
      return {
        text: segment.value,
        id: segment.id,
        comment: true
      };
    }

    return null;
  };

  segments.forEach((segment) => {
    // Handle STATIC segments and PARAMs for paragraphs if we are outside the table
    if (!insideTable && (segment.type === SegmentedTextType.STATIC || segment.type === SegmentedTextType.PARAM || segment.type === SegmentedTextType.COMMENT)) {
      children.push(processSegmentContent(segment, params));
    }

    // Start of STATIC_TABLE
    if (segment.type === SegmentedTextType.STATIC_TABLE_START) {
      insideTable = true; // We are now inside a table

      // Push the current paragraph if it has children
      if (children.length > 0) {
        currentParagraph.children = children;
        newBlocks.push(currentParagraph);
        children = [];
        currentParagraph = { type: 'paragraph', children: [] }; // Reset paragraph
      }

      currentTable = { type: 'table', children: [] }; // Initialize a new table
    }

    // Start of a Table Row
    if (insideTable && segment.type === SegmentedTextType.STATIC_TABLE_ROW_START) {
      currentRow = { type: 'table-row', children: [] }; // Initialize a new row
    }

    // Start of a Table Cell
    if (insideTable && segment.type === SegmentedTextType.STATIC_TABLE_CELL_START) {
      currentCell = { type: 'table-cell', children: [] }; // Initialize a new cell
    }

    // Handle content inside a cell
    if (insideTable && currentCell && (segment.type === SegmentedTextType.STATIC || segment.type === SegmentedTextType.PARAM || segment.type === SegmentedTextType.COMMENT)) {
      currentCell.children.push(processSegmentContent(segment, params)); // Add content to the cell
    }

    // End of a Table Cell
    if (insideTable && segment.type === SegmentedTextType.STATIC_TABLE_CELL_END) {
      currentRow.children.push(currentCell); // Add cell to the row
      currentCell = null; // Reset cell
    }

    // End of a Table Row
    if (insideTable && segment.type === SegmentedTextType.STATIC_TABLE_ROW_END) {
      currentTable.children.push(currentRow); // Add row to the table
      currentRow = null; // Reset row
    }

    // End of STATIC_TABLE
    if (insideTable && segment.type === SegmentedTextType.STATIC_TABLE_END) {
      newBlocks.push(currentTable); // Push the completed table to the blocks
      currentTable = null; // Reset table
      insideTable = false; // Exit table mode
    }
  });

  // Add any remaining paragraph content after processing segments
  if (children.length > 0 && !insideTable) {
    currentParagraph.children = children;
    newBlocks.push(currentParagraph);
  }

  return newBlocks;
};



export function useOutsideAlerter(ref, func, currentSegment) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, func, currentSegment]);
}
export function blockToSegment(block) {
  if(block.type === 'mention'){
    return {
      id: block.id,
      value: block.character,
      paramName: block.paramName,
      type: SegmentedTextType.PARAM
    }
  }
  if(block.comment){
    return {
      id: block.id,
      value: block.text,
      type: SegmentedTextType.COMMENT
    }
  }
  return {
    id: block.id,
    value: block.text,
    type: SegmentedTextType.STATIC
  }
}
// recursive function to extract blocks only with ids
export function blocksToSegments(blocks): ClauseEntity['segmentation']['segmentedText']{
  const segments: ClauseEntity['segmentation']['segmentedText'] = []

  blocks.forEach(block => {
    if(block.id){
        if(block.type === 'mention'){
          segments.push([
            block.id,
            block.paramName,
            SegmentedTextType.PARAM,
          ])}
        if(block.comment){
          segments.push([
            block.id,
            block.text,
            SegmentedTextType.COMMENT,
          ])}
        if(block.text){
          segments.push([
            block.id,
            block.text,
            SegmentedTextType.STATIC,
            {
              bold: block.bold,
              italic: block.italic,
              underline: block.underline,
            }
          ])}
      }
    if(block.children && block.children.length > 0){
      segments.push(...blocksToSegments(block.children))
    }
  }
)
  return segments
}
