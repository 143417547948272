import "./commonmodal.scss";
import React, { useContext, useEffect, useRef, useState, useCallback } from "react";
import { Controller, set, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import Loading from "../../../components/common/Loading";
import InputValuesContext from "../../../contexts/InputValuesContext";
import CustomCombobox from "../../../components/common/CustomCombobox";
import { useTranslation } from '../../../contexts/TranslationProvider';
import { ContractEntity, ProjectEntity, ProjectTemplateRequirementEntity, TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "../../../domain/entities";
import { ContractClient } from "../../../services/api/ContractClient";
import { TypeLevel1Client } from "../../../services/api/TypeLevel1Client";
import { getName } from "../../../helpers/Translation";
import { ContractTemplateClient } from "../../../services/api/ContractTemplateClient";
import { FormClient } from "../../../services/api/FormClient";
import FileInputAdvanced from "../../../components/common/FileInputAdvanced";
import CheckBoxInput from "../../../components/common/CheckBoxInput";
import { DocumentClient } from "../../../services/api/DocumentClient";
import { ProjectClient } from "../../../services/api/ProjectClient";
import { numberToEnumList } from "../../../domain/Project";
import { toast } from "react-toastify";
import { ProjectTemplateRequirementType } from "../../../domain/Project";
import PendingAi from "../../../components/common/newDesign/PendingAi";
import getEntityLookupComboBox from "../../../components/modals/EntityLookupComboBox";
interface CreateContractModalType {
  onClose: () => void;
  dataProject?: { requirement: ProjectTemplateRequirementEntity, project: ProjectEntity };
  typeReq?: number,
  migrateToContract?: boolean,
  formId?: number
}

const ContractLookupComboBox = getEntityLookupComboBox<ContractEntity>('Contract')
function CreateContractModal({ onClose, dataProject, typeReq, migrateToContract, formId }: CreateContractModalType) {
  const { t, language } = useTranslation();
  const { setContractId, setContract, setProject, setRequirement, setRequirementType } = useContext(InputValuesContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contractExistsError, setContractExistsError] = useState(false);
  const [checkContractExistsTimeout, setCheckContractExistsTimeout] = useState<NodeJS.Timeout>(null);
  const [operation, setOperation] = useState<'Create' | 'Import' | 'Select'>('Create');
  const [selectedContract, setSelectedContract] = useState<ContractEntity>(null)
  const [selectedContractError, setSelectedContractError] = useState(false);

  const [isScanned, setIsScanned] = useState(false);
  const modalContentRef = useRef(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [fileError, setFileError] = useState(false);
  type CreateFormFromDocRequest = {
    file: Blob & { name: string };
    name: string;
    level1Id?: TypeLevel1Entity['id'];
    level2Id?: TypeLevel2Entity['id'];
    level3Id?: TypeLevel3Entity['id'];
  }
  const [req, setReq] = useState<CreateFormFromDocRequest>({
    name: "",
    file: undefined
  });
  const [levelsFilter, setLevelsFilter] = useState<{
    level1Id: TypeLevel1Entity['id'];
    level2Id: TypeLevel2Entity['id'];
    level3Id: TypeLevel3Entity['id'];
  }>({
    level1Id: null,
    level2Id: null,
    level3Id: null,
  });
  const [nameLevels, setDisplayLevels] = useState({
    level1: true,
    level2: true,
    level3: true,
  });
  const [options, setOptions] = useState<{
    level1: TypeLevel1Entity[],
    level2: TypeLevel2Entity[],
    level3: TypeLevel3Entity[],
  }>({
    level1: [],
    level2: [],
    level3: [],
  });
  const apiClient = useApiClientWithLoading();
  const contractClient = new ContractClient(apiClient)
  const contractTemplateClient = new ContractTemplateClient(apiClient)
  const formClient = new FormClient(apiClient)
  const documentClient = new DocumentClient(apiClient)
  const projectClient = new ProjectClient(apiClient)

  const typeLevel1Client = new TypeLevel1Client(apiClient)
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await typeLevel1Client.getAllWithContractTemplate(language);
      setOptions((prevOptions) => ({
        ...prevOptions,
        level1: data.rows,
      }));
    };
    setLoading(false);

    fetchData();
  }, []);
  useEffect(() => {
    if (dataProject) {
      setLevelsFilter({
        level1Id: dataProject?.requirement?.level1Id,
        level2Id: dataProject?.requirement?.level2Id,
        level3Id: dataProject?.requirement?.level3Id
      })
    }
  }, [dataProject]);
  const handleLevel1Change = (value) => {
    const selectedLevel1 = options.level1.find((item) => getName(item, language) === value);
    if (selectedLevel1) {
      setLevelsFilter({
        level1Id: selectedLevel1.id,
        level2Id: null,
        level3Id: null
      })
      setOptions((prevOptions) => ({
        ...prevOptions,
        level2: selectedLevel1.levels2,
        level3: [],
      }));
      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level2: selectedLevel1.levels2.length > 0,
        level3: false,
      }));

      // Clear the values of Type2 and Type3 comboboxes
      setValue("Type2", t("pages.pilot.popups.contract.inputs.type2.placeholder"));
      setValue("Type3", t("pages.pilot.popups.contract.inputs.type3.placeholder"));
    }
  };

  const handleComboboxClick = () => {
    setTimeout(() => {
      modalContentRef.current.scrollTo({
        top: modalContentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }, 10);
  };

  const handleLevel2Change = (value) => {
    const selectedLevel2 = options.level2.find((item) => getName(item, language) === value);
    if (selectedLevel2) {
      setLevelsFilter({
        level1Id: levelsFilter.level1Id,
        level2Id: selectedLevel2.id,
        level3Id: null
      })
      setOptions((prevOptions) => ({
        ...prevOptions,
        level3: selectedLevel2.levels3 || [],
      }));

      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level3: Array.isArray(selectedLevel2.levels3) && selectedLevel2.levels3.length > 0,
      }));
    } else {
      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level3: false,
      }));
    }
    setValue("Type3", t("pages.pilot.popups.contract.inputs.type3.placeholder"));
  };
  const handleLevel3Change = (value) => {
    const selectedLevel3 = options.level3.find((item) => getName(item, language) === value);
    if (selectedLevel3) {
      setLevelsFilter({
        ...levelsFilter,
        level3Id: selectedLevel3.id,
      })
    }
  };

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const fillDocumentSummary = useCallback(
    async (file, name) => {
      setLoading(true);
      const { row: { id: documentId } } = await documentClient.upsertMultiForm({ file: req.file, name: contractName, isScanned });
      const { row: template } = await contractTemplateClient.select(levelsFilter.level1Id, levelsFilter.level2Id, levelsFilter.level3Id, language, 'pure');
      if (!template) {
        toast.error("No contract template found");
        throw new Error("No contract template found");
      }
      const templateId = template?.id;
      if (templateId) {
        const summary = await documentClient.fillSummaryWithAIAndContractTemplate(documentId, templateId);
        console.log("object, ", numberToEnumList(typeReq)[0])
        if (dataProject?.project) {
          const updatedProject = await projectClient.update(dataProject?.project?.id, {
            values: {
              ...dataProject.project?.values,
              [dataProject?.requirement.id]: {
                type: ProjectTemplateRequirementType.CONTRACTAI,
                id: summary?.documentId,
              },
            },
            excludedRequirements: [...dataProject.project.excludedRequirements]
          });
        }
        return summary;
      }
      return;
    },
    [apiClient, setLoading]
  );

  const onSubmit = async (data) => {
    setLoading(true);
    if (dataProject) {
      if (operation == 'Create') {
        const { row: contractTemplate } = await contractTemplateClient.select(levelsFilter.level1Id, levelsFilter.level2Id, levelsFilter.level3Id, language, 'full');
        if (!contractTemplate) {
          setLoading(false);
          return toast("Contract Template not found")
        }
        setLoading(false);
        setContractId(null);
        setContract(null);
        setProject(dataProject?.project)
        setRequirement(dataProject?.requirement)
        setRequirementType(typeReq)

        const contract = await contractClient.create({
          name: contractName,
          templateId: contractTemplate.id
        })
        await projectClient.update(dataProject.project?.id, {
          values: {
            ...dataProject.project?.values,
            [dataProject.requirement.id]: {
              type: ProjectTemplateRequirementType.CONTRACT,
              id: contract.id,
            },
          },
        });

        return navigate(`/edition-contrat/${contract.id}`, {
          state: {
            previousPathname: [
              { link: '/projets', label: t('sidebar.myProjects') },
              { link: `/projet/${dataProject.project.id}`, label: dataProject.project?.name }
            ]
          }
        });

      } else if (operation == 'Import') {
        try {
          if (!req.file) {
            setFileError(true);
            setLoading(false);
            return;
          }
          setUploadLoading(true);
          const summary = await fillDocumentSummary(req.file, req.name);
          await projectClient.update(dataProject.project?.id, {
            values: {
              ...dataProject.project?.values,
              [dataProject.requirement.id]: {
                type: ProjectTemplateRequirementType.CONTRACTAI,
                id: summary.documentId,
              },
            },
          });
          navigate(`/document-summary/${summary.documentId}`,
            {
              state: {
                project: dataProject.project,
                previousPathname: [
                  { link: '/projets', label: t('sidebar.myProjects') },
                  { link: `/projet/${dataProject.project.id}`, label: dataProject.project?.name }
                ]
              }
            });
          setUploadLoading(false);
        } catch (err) {
          console.error(err);
          setUploadLoading(false);
        } finally {
          setLoading(false);
          setUploadLoading(false);
        }
      } else if (operation == 'Select') {
        try {
          if (!selectedContract) {
            setSelectedContractError(true);
            setLoading(false);
            return;
          }
          setLoading(false);
          setContractId(null);
          setContract(null);
          setProject(dataProject?.project)
          setRequirement(dataProject?.requirement)
          setRequirementType(typeReq)
          setUploadLoading(true);
          await projectClient.update(dataProject.project?.id, {
            values: {
              ...dataProject.project?.values,
              [dataProject.requirement.id]: {
                type: ProjectTemplateRequirementType.CONTRACT,
                id: selectedContract.id,
              },
            },
          });
          return navigate(`/edition-contrat/${selectedContract.id}`, {
            state: {
              previousPathname: [
                { link: '/projets', label: t('sidebar.myProjects') },
                { link: `/projet/${dataProject.project.id}`, label: dataProject.project?.name }
              ]
            }
          });

        } catch (err) {
          console.error(err);
          setUploadLoading(false);
        } finally {
          setLoading(false);
          setUploadLoading(false);
        }
      }
    }


  };

  const contractName = watch("contractName");
  useEffect(() => {
    if (checkContractExistsTimeout) {
      clearTimeout(checkContractExistsTimeout)
    }
    setCheckContractExistsTimeout(setTimeout(async () => {
      try {
        const count = await contractClient.countAllByFilter({ "name": contractName });
        setContractExistsError(count >= 1);
      } catch (error) {
        console.error(error);
      } finally {
        setCheckContractExistsTimeout(null)
      }
    }, 500))
  }, [contractName]);

  return (

    <>
      <div className="modal-backdrop fade show"></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{migrateToContract ? t("pages.editionForm.exportToContract") : t("pages.pilot.popups.contract.title")}</h5>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"
                  style={{ marginRight: language == "ar" && "88%" }}
                ></button>
              </div>
              <div className="modal-body modal-body-pilotage" ref={modalContentRef}>
                {loading ? (
                  <PendingAi height="100%" />
                ) : (
                  <>
                    <div className="modal-body-multi-choice">
                      <div className={`modal-body-choice-${operation == 'Create' ? "active" : "inactive"}`} onClick={() => setOperation('Create')}>
                        {t("pages.pilot.cards.contract.title")}
                      </div>
                      <div className={`modal-body-choice-${operation == 'Import' ? "active" : "inactive"}`} onClick={() => setOperation('Import')}>
                        {t("pages.listener.cards.contract.title")}
                      </div>
                      <div className={`modal-body-choice-${operation == 'Select' ? "active" : "inactive"}`} onClick={() => setOperation('Select')}>
                        {"Choisir contrat existant"}
                      </div>
                    </div>
                    <form action="" className="form-group" onSubmit={handleSubmit(onSubmit)}
                      style={{ maxHeight: '550px', marginBottom: "20%" }}>
                      <p className="fw-bolder">{t("pages.pilot.popups.contract.required")}</p>
                      {(operation == 'Create' || operation == 'Import') && <div className="form-input-content">
                        <div className="w-50">
                          <label htmlFor="contractName" className="case-form-labelName">{t("pages.pilot.popups.contract.inputs.contractName.name")}</label>
                          <div className="form-floating mb-3 case-form-labelInput">
                            <input
                              type="text"
                              className="form-control custom-color-input input-popup"
                              id="contractName"
                              placeholder={t("pages.pilot.popups.contract.inputs.contractName.placeholder")}
                              onChange={(e) => { setContractExistsError(false) }}
                              {...register("contractName", { required: true })}
                            />
                          </div>
                          {errors.contractName && <p className="text-danger py-0 my-0 py-0 my-0">{t("pages.pilot.popups.contract.inputs.contractName.error1")}</p>}
                          {contractExistsError && <p className="text-danger py-0 my-0 py-0 my-0">{t("pages.pilot.popups.contract.inputs.contractName.error2")}</p>}
                        </div>
                        {dataProject ? (
                          <div className="content-levels">
                            <label className="case-form-labelName">{t("pages.pilot.popups.contract.inputs.type1.name")}</label>
                            <select value={getName(dataProject?.requirement?.level1!, language)} disabled>
                              <option>{getName(dataProject?.requirement?.level1!, language)}</option>
                            </select>
                            {dataProject?.requirement?.level2 && (
                              <>
                                <label className="case-form-labelName">{t("pages.pilot.popups.contract.inputs.type2.name")}</label>
                                <select value={getName(dataProject?.requirement?.level2!, language)} disabled>
                                  <option>{getName(dataProject?.requirement?.level2!, language)}</option>
                                </select>
                              </>
                            )}
                            {dataProject?.requirement?.level3 && (
                              <>
                                <label className="case-form-labelName">{t("pages.pilot.popups.contract.inputs.type3.name")}</label>
                                <select value={getName(dataProject?.requirement?.level3!, language)} disabled>
                                  <option>{getName(dataProject?.requirement?.level3!, language)}</option>
                                </select>
                              </>
                            )}
                          </div>
                        ) : (
                          <div style={{ width: "40%" }}>
                            <Controller
                              name="Type1"
                              control={control}
                              defaultValue={t("pages.pilot.popups.contract.inputs.type1.placeholder")}
                              rules={{
                                required: true,
                                validate: (value) => value !== t("pages.pilot.popups.contract.inputs.type1.placeholder"),
                              }}
                              render={({ field }) => (
                                <CustomCombobox
                                  fullWidth
                                  label={t("pages.pilot.popups.contract.inputs.type1.name")}
                                  options={options.level1.map((item) => getName(item, language))}
                                  value={dataProject ? getName(dataProject?.requirement?.level1, language) : field.value}
                                  onDropdownOpen={handleComboboxClick}
                                  onChange={(value) => {
                                    handleLevel1Change(value);
                                    field.onChange(value);
                                  }}
                                  isOpen={openCombobox === "level1"}
                                  setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level1" : null)}
                                />
                              )}
                            />

                            {errors.Type1 && <p className="text-danger py-0 my-0">{t("pages.pilot.popups.contract.inputs.type1.error")}</p>}
                            {nameLevels.level2 && (
                              <Controller
                                name="Type2"
                                control={control}
                                defaultValue={t("pages.pilot.popups.contract.inputs.type2.placeholder")}
                                rules={{
                                  required: true,
                                  validate: (value) => value !== t("pages.pilot.popups.contract.inputs.type2.placeholder"),
                                }}
                                render={({ field }) => (
                                  <CustomCombobox
                                    fullWidth
                                    label={t("pages.pilot.popups.contract.inputs.type2.name")}
                                    onDropdownOpen={handleComboboxClick}
                                    options={options.level2.map((item) => getName(item, language))}
                                    value={dataProject ? getName(dataProject?.requirement?.level2!, language) : field.value}
                                    onChange={(value) => {
                                      handleLevel2Change(value);
                                      field.onChange(value);
                                    }}
                                    isOpen={openCombobox === "level2"}
                                    setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level2" : null)}
                                  />
                                )}
                              />
                            )}
                            {options.level2.length > 0 && errors.Type2 && <p className="text-danger py-0 my-0">{t("pages.pilot.popups.contract.inputs.type2.error")}</p>}
                            {nameLevels.level3 && (
                              <Controller
                                name="Type3"
                                control={control}
                                defaultValue={t("pages.pilot.popups.contract.inputs.type3.placeholder")}
                                rules={{
                                  required: true,
                                  validate: (value) => value !== t("pages.pilot.popups.contract.inputs.type3.placeholder"),
                                }}
                                render={({ field }) => (
                                  <CustomCombobox
                                    fullWidth
                                    label={t("pages.pilot.popups.contract.inputs.type3.name")}
                                    onDropdownOpen={handleComboboxClick}
                                    options={options.level3.map((item) => getName(item, language))}
                                    value={dataProject ? getName(dataProject?.requirement?.level3!, language) : field.value}
                                    onChange={(value) => {
                                      handleLevel3Change(value);

                                      field.onChange(value)
                                    }}
                                    isOpen={openCombobox === "level3"}
                                    setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "level3" : null)}
                                  />
                                )}
                              />
                            )}

                            {options.level3.length > 0 && errors.Type3 && <p className="text-danger py-0 my-0">{t("pages.pilot.popups.contract.inputs.type3.error")}</p>}
                          </div>)}
                      </div>}
                      {operation == 'Import' && <>
                        <FileInputAdvanced
                          value={req.file}
                          label={t("pages.listener.popups.contract.inputs.file.placeholder")}
                          onChange={(file) => {
                            setFileError(false)
                            setReq({
                              ...req, file
                            })
                          }}
                          className="mt-3"
                        />
                        {fileError && <p className="text-danger py-0 my-0 py-0 my-0">{t("pages.pilot.popups.form.inputs.fileError")}</p>}
                        <CheckBoxInput
                          checked={isScanned}
                          name={t("pages.listener.popups.contract.inputs.isScanned")}
                          onChange={() => setIsScanned(!isScanned)}
                          disabled={false}
                        />
                      </>}
                      {operation == 'Select' && <>
                        <ContractLookupComboBox
                          display={(contract) => contract?.name ?? ""}
                          filter={(contract) => contract.level1Id == levelsFilter.level1Id
                            && contract.level2Id == levelsFilter.level2Id
                            && contract.level3Id == levelsFilter.level3Id
                            && contract.activeTemplate
                          }
                          lookUpField="name"
                          onChange={setSelectedContract}
                          value={selectedContract}
                        />
                        {selectedContractError && <p className="text-danger py-0 my-0 py-0 my-0">"{"Contrat obligatoir"}"</p>}

                      </>}
                      <div className="modal-footer" style={{ marginTop: "100px" }}>
                        <button
                          disabled={contractExistsError || !!checkContractExistsTimeout}
                          type="submit" className={`btn btn-primary btn-import ${migrateToContract && "btn-display"} `} style={{ left: "4.5%" }}>
                          {t("pages.pilot.popups.contract.button")}
                        </button>
                      </div>
                      {/**
                     * <div className="modal-footer" style={{ marginTop: "100px" }}>
                      <button
                        disabled={formExistsError || !!checkFormExistsTimeout}
                        type="submit" className="btn btn-primary btn-import" style={{ left: "4.5%" }}>
                        {t("pages.pilot.popups.form.button")}
                      </button>
                    </div>
                     */}
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}

export default CreateContractModal;
