
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ClauseEntity, ContractEntity, SubClauseEntity } from "../../../domain/entities";
import { findParamBoundaries, getRenderSegments } from "../../../helpers/segmentation";
import { RenderSegments, SegmentedTextType } from "../../../domain/types/ClauseParams";
import { renderStyledSegment } from "../../../helpers/renderSegment";
import SelectionContext from "../../../contexts/SelectionContext";
import "./renderTemplateSegmentedText.scss"
import SlateEditor from "./StaleEditor/SlateEditor";
import { useTranslation } from "../../../contexts/TranslationProvider";
import RenderTemplateSingleSegmentedText from "./RenderTemplateSingleSegmentedText";
import RenderTemplateSegmentedTable from "./RenderTemplateSegmentedTable";


interface RenderTemplateSegmentedTextProps {
	inputValues: Record<string, any>;
	clauseId: ClauseEntity['id'];
	segmentation: ClauseEntity['segmentation'];
	subClauseId?: SubClauseEntity['id'];
	fileNames?: ContractEntity['fileNames'];
	beneficialsMap?: ContractEntity['beneficialsMap'];
	segmentsOverrides: Record<string, string>;
	//setSegmentsOverrides: (v: Record<string, string>) => void;
	onSegmentChange: (id: string, text: string) => void
	isSelected: boolean;
	isEditing: boolean;
	formattedRawRef?: React.MutableRefObject<any>;
	setOpenedPopups: (num: any) => void;
	openPopups: Boolean
}
function RenderTemplateSegmentedText({
	clauseId,
	segmentation,
	subClauseId,
	//setSegmentsOverrides,
	segmentsOverrides,
	onSegmentChange,
	isSelected,
	isEditing,
	inputValues,
	setOpenedPopups,
	openPopups
}: RenderTemplateSegmentedTextProps) {

	const { t, language, tFromTranslations } = useTranslation();

	const {
		setSelected,
		selected,
		isClauseEditing,
		setIsClauseEditing,
	} = useContext(SelectionContext);
	const [changedParamBoundaries, setChangedParamBoundaries] = useState<[number, number][]>([])

	useEffect(() => {
		const paramNames = renderSegments.map(seg => (seg as any).paramName).filter(seg => seg)
		if (selected.eventType == 'ParamValueChange' && selected.paramName && paramNames.includes(selected.paramName)) {
			const boundaries = findParamBoundaries(renderSegments, selected.paramName)
			setChangedParamBoundaries(boundaries)
		} else {
			setChangedParamBoundaries([])
		}
	}, [selected]);

	// use usememo for renderSegments
	let renderSegments: RenderSegments = getRenderSegments(segmentation.segmentedText, inputValues,
		{}, {}, segmentation.segmentedParams, t, language)
		.map(seg => {
			return {
				...seg,
				value: seg.value?.replaceAll(/\r\n/g, "\n"),
			}
		})

	const persistentSegments = useMemo(() => {
		const renderSegments = getRenderSegments(segmentation.segmentedText, inputValues,
			{}, {}, segmentation.segmentedParams, t, language)
			.map(seg => {
				return {
					...seg,
					value: seg.value?.replaceAll(/\r\n/g, "\n"),
				}
			})
		const paramNames = renderSegments.map(seg => (seg as any).paramName).filter(seg => seg)
		if (selected.eventType == 'ParamValueChange' && selected.paramName && paramNames.includes(selected.paramName)) {
			const boundaries = findParamBoundaries(renderSegments, selected.paramName)
			return renderSegments.map((seg, idx) => {
				const isHighlighted = boundaries.some((b) => b[0] < idx && idx < b[1])
				return {
					...seg,
					isHighlighted,
				}
			})
		} else {
			return renderSegments
		}
	}, [segmentation.segmentedText, inputValues, segmentation.segmentedParams])

	const filteredSegments = [];
	let isInTable = false; // Flag to track if we are inside a table
	
	renderSegments.forEach((segment, idx) => {
		(segment as any).actualIdx = idx;
			if (segment.type === SegmentedTextType.STATIC_TABLE_START) {
					// Extract segments for the table separately
					const paramName = segment.paramName;
					const tableEndIndex = renderSegments.findIndex(
							seg => seg.type === SegmentedTextType.STATIC_TABLE_END && seg.paramName === paramName
					);
	
					// Extract table segments
					const tableSegments = renderSegments.slice(idx, tableEndIndex + 1);
					
					// Render table component
					const tableComponent = (
							<RenderTemplateSegmentedTable
									key={`table-${paramName}`}
									tableSegments={tableSegments}
									segmentation={segmentation}
									segmentsOverrides={segmentsOverrides}
									changedParamBoundaries={changedParamBoundaries}
									isSelected={isSelected}
							/>
					);
					filteredSegments.push(tableComponent);
					isInTable = true;
					return;
			}
			if (segment.type === SegmentedTextType.STATIC_TABLE_END) {
					isInTable = false;
					return;
			}
	
			// If we are not inside a table, push the segment to filteredSegments
			if (!isInTable) {
					filteredSegments.push(
							<RenderTemplateSingleSegmentedText
									key={idx}
									idx={idx}
									segment={segment}
									isSelected={isSelected}
									segmentation={segmentation}
									changedParamBoundaries={changedParamBoundaries}
									segmentsOverrides={segmentsOverrides}
							/>
					);
			}
	});
	
	return (
		<div className="template-render" style={{ marginBottom: '20px', marginTop: "5px" }}>
			{isEditing ? (
				(<>
					<SlateEditor
						clauseId={clauseId}
						subClauseId={subClauseId}
						onSegmentChange={onSegmentChange}
						segments={persistentSegments}
						params={segmentation.segmentedParams}
						setOpenedPopups={setOpenedPopups}
						openPopups={openPopups}
					/>

				</>)) : (<>
					{filteredSegments}
				</>
			)
			}

		</div>
	);
}

export default RenderTemplateSegmentedText;
