import React, { useContext } from "react";
import InputValuesContext from "../../../../contexts/InputValuesContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { useParamProcessing } from "../../../../hooks/useParamProcessing";
import { useSubmitContract } from "../../../../hooks/useSubmitContract";
import { ContractEntity } from "../../../../domain/entities";
import '../buttons.scss'
import { LoadingRessourceContext } from "../../../../contexts/LoadingRessourceContext";
import { useNavigate, useSearchParams } from "react-router-dom";

function EditionContractSaveButton() {
  const {
    inputValues,
    segmentsOverrides,
    commentsOverrides,
    additionalClauses,
    excludedClauses,
    excludedSubClauses,
    templateId,
    templateData,
    completionPercentage,
    contractId,
  } = useContext(InputValuesContext);
  const {setLoadingRessources} = React.useContext(LoadingRessourceContext);

  const processParamValues = useParamProcessing();
  const submitContract = useSubmitContract();
  const [searchParams] = useSearchParams();
  const restrictedToken = searchParams.get("restrictedToken")
const navigate=useNavigate()
  const { t } = useTranslation();
  const handleFormSubmit = async () => {
    try{
      setLoadingRessources((prev) => [...prev, { id: contractId, type: "Contract" }]);
      const { processedParamValues, hasError } = await processParamValues(
        inputValues
      );
  
      if (!hasError) {
  
        let status: ContractEntity['status'] = "Draft";
        if (completionPercentage === 100) {
          status = "Done";
        }
        const body = {
          templateId: templateId,
          paramValues: processedParamValues,
          segmentsOverrides: segmentsOverrides,
          commentsOverrides,
          additionalClauses,
          excludedSubClauses: excludedSubClauses,
          excludedClauses: excludedClauses,
          status: status,
          name: inputValues.contractName,
        };
  
        await submitContract(body, contractId);
        if(restrictedToken){
          navigate("/sign-in")
        }
      }
    }catch(err){
      console.log(err)
    }finally{
      setLoadingRessources((prev) => prev.filter((ressource) => ressource.id !== contractId && ressource.type === "Contract"));
    }
  };

  return (
    <button className="navbar-button" onClick={handleFormSubmit}>
      {completionPercentage !== 100
        ? t("navbar.save2")
        : t("navbar.save1")}
    </button>
  );
}

export default EditionContractSaveButton;
