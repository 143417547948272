import React, { useMemo, useCallback, useRef, useEffect, useState, useContext } from 'react'
import { Editor, Transforms, Range, createEditor, Descendant, Element as SlateElement, node, } from 'slate'
import { withHistory } from 'slate-history'
import {
  Slate,
  Editable,
  ReactEditor,
  withReact,
  useSlate
} from 'slate-react'
import { MentionElement } from './custom-types'
import "./stale.scss"
import AddParamDialog from './AddParamDialog'
import Element from './components/Element'
import Leaf from './components/Leaf'
import EditContractTemplateContext from '../../../../contexts/EditContractTemplateContext'
import { ClauseEntity, SubClauseEntity } from "../../../../domain/entities";
import { buildBlocks, useOutsideAlerter, blocksToSegments, blockToSegment } from './components/helper'
import { withTables } from './components/table'
import { useTranslation } from '../../../../contexts/TranslationProvider'
import { RenderSegments, SegmentedText, SegmentedTextType } from '../../../../domain/types/ClauseParams'
import { ParamDefinitionClient } from '../../../../services/api/ParamDefinition'
import { ParamDefinitionEntity } from '../../../../domain/entities/ParamDefinitionEntity'
import useApiClientWithLoading from '../../../../services/api/ApiClient'
import { Toolbar, Button, Icon } from './components/components'
import { BsJustifyLeft } from "react-icons/bs";
import { BsJustifyRight } from "react-icons/bs";
import { BsJustify } from "react-icons/bs";
import { FiAlignCenter } from "react-icons/fi";
interface SlateEditorProps {
  clauseId: ClauseEntity['id'];
  subClauseId?: SubClauseEntity['id'];
  params: any;
  onSegmentChange: (id: string, text: string) => void;
  segments: any;
  setOpenedPopups: (num: any) => void;
  openPopups: Boolean
}

const SlateEditor = ({ segments, params, onSegmentChange, clauseId, subClauseId, setOpenedPopups, openPopups }: SlateEditorProps) => {

  const { onSegmentDelete, onAddParam, combinedTemplateParams, onApplySegmentation, contractTemplate } = useContext(EditContractTemplateContext)
  const ref = useRef<HTMLDivElement | null>(null)
  const [blocks, setBlocks] = useState(buildBlocks(segments ? segments : [], params));
  const [chars, setChars] = useState(combinedTemplateParams)
  const apiClient = useApiClientWithLoading();
  const paramDefinitionClient = new ParamDefinitionClient(apiClient);
  const [allParams, setAllParams] = useState<ParamDefinitionEntity[]>([])
  const [insideTable, setInsideTable] = useState(false)

  const [target, setTarget] = useState<Range | undefined>(undefined)
  const [index, setIndex] = useState(0)
  const divRef = useRef(null);
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const [currentSegment, setCurrentSegment] = useState<any | null>({ id: "", text: "" })
  const [deletedSegments, setDeletedSegments] = useState([])
  const [search, setSearch] = useState('')
  const { language } = useTranslation()
  useEffect(() => {
    const fetchParams = async () => {
      try {
        const response = await paramDefinitionClient.getAll();
        setAllParams(response.rows);
      } catch (error) {
        console.error('Error fetching params:', error);
      }
    };
    fetchParams();
  }, []);
  const withMentions = editor => {
    const { isInline, isVoid, markableVoid } = editor

    editor.isInline = element => {
      return element.type === 'mention' ? true : isInline(element)
    }

    editor.isVoid = element => {
      return element.type === 'mention' ? true : isVoid(element)
    }

    editor.markableVoid = element => {
      return element.type === 'mention' || markableVoid(element)
    }

    return editor
  }
  const editor = useMemo(
    () => withTables(withMentions(withReact(withHistory(createEditor())))),
    []
  )


  useEffect(() => {
    const updateEditorContent = (editor, segments, params) => {
      const newBlocks = buildBlocks(segments, params) as any;
      // reset state editor to new 
      setBlocks(newBlocks);
      setDeletedSegments([])
      if (editor.selection) {
        if (editor.selection.anchor.path.length > 2) {
          editor.selection = null;
          setCurrentSegment({ id: "", text: "" })
          editor.children = newBlocks;
          return;
        }

        const selectedIdx = editor.selection.anchor.path[1]
        const selectedNewBlock = newBlocks[0].children[selectedIdx]
        const selectedOld = Editor.node(editor, editor.selection)[0] as any
        const offsets = [editor.selection.anchor.offset, editor.selection.focus.offset]

        if (!selectedNewBlock) {
          editor.selection = null;
          setCurrentSegment({ id: "", text: "" })
          editor.children = newBlocks;
          return;
        }

        if (selectedNewBlock.type === "mention") {
          editor.selection = null;
          setCurrentSegment({ id: "", text: "" })
          editor.children = newBlocks;
          return;
        }

        offsets.forEach((offset, idx) => {
          if (offset > selectedNewBlock.text.length - 1) {
            editor.selection = null;
            setCurrentSegment({ id: "", text: "" })
            editor.children = newBlocks;
            return;
          }
        })
        editor.children = newBlocks;
      } else {
        editor.children = newBlocks;
      }
    };
    setChars(combinedTemplateParams)
    updateEditorContent(editor, segments, params);
  }, [segments, params]);

  useEffect(() => {
    setChars(combinedTemplateParams)
  }, [combinedTemplateParams])

  const cleanComments = () => {
    try {
      const segmentationComments = segments.filter((segment) => segment.type === SegmentedTextType.COMMENT)
      segmentationComments.forEach(element => {
        const [paragraphIdx, selectionIdx] = editor.selection.anchor.path
        const actualParagraph: any = editor.children[paragraphIdx]
        const children = actualParagraph.children
        if (children) {
          const found = children.find((child) => child.id === element.id)
          if (!found) {
            const segments = blocksToSegments(editor.children)
            onSegmentDelete(clauseId, subClauseId, element.id, segments, deletedSegments)
          }
        }
      });
    } catch (err) {
      console.log("error cleaning comments: " + err)
    }
  }

  const extractDeletedSegments = (segments, updatedSegments) => {
    const deletedSegments = []
    segments.forEach((segment) => {
      const found = updatedSegments.find((updatedSegment) => updatedSegment[0] === segment.id)
      if (!found) {
        deletedSegments.push(segment)
      }
    })
    console.log("deleted segments", deletedSegments)
    return deletedSegments
  }


  const handlePaste = (event) => {
    const text = event.clipboardData.getData('text/plain')
    const { selection } = editor

    // insert text at the current selection
    if (selection) {
      Transforms.insertText(editor, text, { at: selection })
    }
    event.preventDefault()
  }

  const onKeyDown = useCallback(
    event => {
      if (target && chars.length > 0) {
        const filteredParams = chars.filter(char => char.label ? char.label.toLowerCase().includes(search.toLowerCase()) : false)
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault()
            const prevIndex = index >= filteredParams.length - 1 ? 0 : index + 1
            setIndex(prevIndex)
            break
          case 'ArrowUp':
            event.preventDefault()
            const nextIndex = index <= 0 ? filteredParams.length - 1 : index - 1
            setIndex(nextIndex)
            break
          case 'Tab':
          case 'Enter':
            event.preventDefault()
            Transforms.select(editor, target)
            handleInsert(filteredParams[index], "")
            setTarget(null)
            break
          case 'Escape':
            event.preventDefault()
            setTarget(null)
            break
        }
      }
      if (event.key === 'Tab') {
        event.preventDefault();
        Transforms.insertText(editor, '\t');
      }
      if (event.key === 'Enter') {
        event.preventDefault();
        Transforms.insertText(editor, '\n');
      }
      // handle deletion of mention when backspace is pressed on a mention element. dont check for target
      if (event.key === 'Backspace' && editor.selection && editor.selection.anchor.path.length > 2) {
        // get the parent
        const parent: any = Editor.parent(editor, editor.selection)

        if (parent[0].type === 'mention') {
          const segments = blocksToSegments(editor.children)
          //onApplySegmentation(clauseId, subClauseId, segments, deletedSegments)
          onSegmentDelete(clauseId, subClauseId, parent[0].id, segments, deletedSegments)
        }
      }
      if (event.key === 'Backspace' && editor.selection && editor.selection.anchor.offset === 0 && editor.selection.focus.offset === 0) {
        event.preventDefault()
        return
      }
    },
    [chars, editor, index, target, deletedSegments]
  )

  const updatePopupPosition = (rect, el) => {
    try {
      if (rect && el && divRef.current) {
        //update position of dialog inside the divRef
        const divRect = divRef.current.getBoundingClientRect()
        const top = rect.top - divRect.top
        const left = rect.left - divRect.left
        const divRectWidth = divRect.width
        el.style.display = 'block'
        el.style.top = `${top + 20}px`
        if (language === 'ar') {
          if (left < 270) {
            el.style.left = `${left}px`
            el.style.right = 'auto'
          } else {
            el.style.left = `${left - 260}px`
            el.style.right = 'auto'
          }
        } else {
          //el.style.left = `${left + 15}px`
          if (left + 270 > divRectWidth) {
            el.style.left = `${left - 250}px`
          } else {
            el.style.left = `${left + 15}px`
          }
          el.style.right = 'auto'
        }
      }
    } catch (e) {
      console.log(e)
    }

  }

  useEffect(() => {
    if (target) {
      // check if target is present in editor
      let domRange = null
      try {
        domRange = ReactEditor.toDOMRange(editor, target)
      } catch (e) {
        console.log(e)
      }
      if (!domRange) {
        setTarget(null)
        setOpenedPopups(false)
        return
      }
      setOpenedPopups(true)
      const rect = domRange.getBoundingClientRect()
      const el = ref.current
      updatePopupPosition(rect, el)
    } else {
      const el = ref.current
      if (el) {
        el.style.display = 'none'
      }
      setOpenedPopups(false)
    }
  }, [chars.length, editor, index, target, language])


  // not used for now, maybe used later
  const insertMention = (editor, param) => {
    const mention: MentionElement = {
      type: 'mention',
      character: param.label,
      children: [{ text: param.name }],
    }
    Transforms.insertNodes(editor, mention)
    Transforms.move(editor)
  }

  const compareSegments = (renderSegments: RenderSegments, segmentedText: SegmentedText) => {
    if (renderSegments.length !== segments.length) {
      return false
    }
    for (let i = 0; i < renderSegments.length; i++) {
      const foundText = segmentedText.find((segment) => segment[0] === renderSegments[i].id)
      if (!foundText) {
        continue
      }
      if ((foundText[2] === renderSegments[i].type) && (foundText[1] !== renderSegments[i].value)) {
        return false
      }
    }
    return true
  }

  const clickOutsideHandler = useCallback(() => {
    const newSegments = blocksToSegments(editor.children)
    const skipChanges = compareSegments(segments, newSegments)
    if (!skipChanges) {
      onApplySegmentation(clauseId, subClauseId, newSegments, deletedSegments)
      console.log("updating from editor")
    }

  }, [currentSegment, deletedSegments, editor])

  const handleInsert = (param, field) => {
    const parentNode: any = Editor.parent(editor, editor.selection)
    const currentNode = Editor.node(editor, editor.selection)
    const parent = parentNode[0];
    const parentType = parent?.type;
    const current = currentNode[0];
    const nodeId = (current as any).id;
    const nodeText = (current as any).text;

    const [paragraphIdx, selectionIdx] = editor.selection.anchor.path
    const actualParagraph: any = editor.children[paragraphIdx]
    const actualSegment: any = actualParagraph.children[selectionIdx]
    if (!nodeId)
      return;
    let textBefore: string = ""
    let textAfter: string = ""
    if (language === 'ar') {
      textBefore = nodeText?.substring(0, target.focus.offset - 1)
      textAfter = nodeText?.substring(target.focus.offset)
    } else {
      textBefore = nodeText?.substring(0, target.anchor.offset)
      textAfter = nodeText?.substring(target.focus.offset)
    }
    //insert mention in the editor
    const mention = {
      type: 'mention' as 'mention',
      character: param.label,
      id: nodeId,
      paramName: param.name,
      children: [{ text: '' }],
    }
    Transforms.select(editor, target)
    Transforms.insertNodes(editor, mention)
    const segments = blocksToSegments(editor.children)
    onAddParam(clauseId, subClauseId, nodeId, param, textBefore, textAfter, segments, field, deletedSegments)
    editor.selection = null;
    setCurrentSegment({ id: "", text: "" })
    setTarget(null)
    console.log("update segments")
  }

  const handleClose = () => {
    setOpenedPopups(false)
    setTarget(null)
  }
  // if click outside set selection null
  useOutsideAlerter(divRef, clickOutsideHandler, currentSegment);
  return (
    <div ref={divRef} style={{ backgroundColor: "white" }} className='stale'>
      <Slate
        key={`${clauseId}-${subClauseId}`}
        editor={editor}
        initialValue={blocks}
        onValueChange={value => {
        }}
        onSelectionChange={(selection) => {
          
        }}
        onChange={(value) => {
          const deletedSegments = extractDeletedSegments(segments, blocksToSegments(editor.children))
          setDeletedSegments(deletedSegments)
          const parentNode: any = Editor.parent(editor, editor.selection)
          const parent = parentNode[0];
          const parentType = parent?.type;
          if(parentType === 'table-cell') {
            setInsideTable(true)
          }else{
            setInsideTable(false)
          }
          const { selection } = editor
          if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection)
            const wordBefore = Editor.before(editor, start, { unit: 'word' })
            const before = wordBefore && Editor.before(editor, wordBefore)
            const beforeRange = before && Editor.range(editor, before, start)
            const beforeText = beforeRange && Editor.string(editor, beforeRange)
            const node = Editor.node(editor, start)
            const enteredChar = (node[0] as any).text.charAt(start.offset - 1)
            // change logic for arabic language
            if (enteredChar == "@" && language === 'ar') {
              setTarget(editor.selection)
              setIndex(0)
              return
            }
            if (enteredChar !== "@" && language === 'ar') {
              setTarget(null)
              return
            }
            const beforeMatch = beforeText && beforeText.match(/@(\w+)$/)
            const after = Editor.after(editor, start)
            const afterRange = Editor.range(editor, start, after)
            const afterText = Editor.string(editor, afterRange)
            const afterMatch = afterText.match(/^(\s|$)/)

            if (beforeMatch && afterMatch) {
              setTarget(beforeRange)
              setSearch(beforeMatch[1])
              setIndex(0)
              return
            }
          }
          setTarget(null)
          cleanComments()
        }}
      >
        <Toolbar>
          <MarkButton format="bold" icon={<span style={{ backgroundColor: "bold" }}>B</span>} />
          <MarkButton format="italic" icon={<em style={{ backgroundColor: "bold" }}>I</em>} />
          <MarkButton format="underline" icon={<u style={{ backgroundColor: "bold" }}>U</u>} />
        </Toolbar>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={onKeyDown}
          onPaste={handlePaste}
          style={{ padding: '20px', border: '2px solid #ccc', borderTop: 'none' }}
        />
        <div
          ref={ref}
          style={{
            position: 'absolute',
            zIndex: 9999,
            padding: '3px',
            background: 'white',
            borderRadius: '4px',
            boxShadow: '0 1px 5px rgba(0,0,0,.2)',
            border: '1px solid #2F14E5',
            width: '270px',
            display: 'none'
          }}
          data-cy="mentions-portal"
        >
          <AddParamDialog handleClose={handleClose} search={search} allParams={allParams} chars={chars} insertMention={handleInsert} index={index} insideTable={insideTable}/>
        </div>
      </Slate>
    </div>
  )
}
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']
const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}
const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}
const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  )

  return !!match
}
const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties: Partial<SlateElement>
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : format,
    }
  }
  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

export default SlateEditor
