import React, { useState } from "react";
import "./tooltip.scss";

const Tooltip = ({ infoText, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className="tooltip-container"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      <div className={`tooltip ${showTooltip ? "open" : ""}`}>
      <div className="arrow-down" />
      <span>
        {infoText}
      </span>
        
      </div>
    </div>
  );
};

export default Tooltip;