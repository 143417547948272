import React, { useContext, useState, useEffect, useRef } from "react";
import Loading from "../../common/Loading";
import InputValuesContext from "../../../contexts/InputValuesContext";
import { downloadFile } from "../../../utils/file";
import { serializeParamValues } from "../../../hooks/serializeParamValues";
import { saveWorkbook } from "../../../utils/csv";
import { useTranslation } from "../../../contexts/TranslationProvider";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { getContractExportData } from "../../../domain/Contract";
import { ContractDocxExportClient } from "../../../services/api/ContractDocxExportClient";
import { toast } from "react-toastify";
// import csv from "csv.png"


const ExportModal = ({ onClose }) => {
  const modalref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalref.current && !modalref.current.contains(event.target)) {
        onClose()
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalref]);
  const [loading, setLoading] = useState(false);
  const { inputValues, templateData, excludedClauses, excludedSubClauses, contract, fileNames, beneficialsMap } = useContext(InputValuesContext);
  const contractName = inputValues.contractName ?? contract?.name
  const { t } = useTranslation();
  const apiClient = useApiClientWithLoading(setLoading);


  const GenerateDocxUrl = async () => {
    const data = getContractExportData(contract, t)
    let contractDocxExportId = contract.template?.contractDocxExportId
    const contractDocxExportClient = new ContractDocxExportClient(apiClient)
    if (!contractDocxExportId) {
      const defaultContractDocxExport = await contractDocxExportClient.getDefault()
      if (!defaultContractDocxExport) {
        toast("No default ContractDocxExport")
        onClose()
        return
      }
      contractDocxExportId = defaultContractDocxExport.id
    }
    setLoading(true)
    const url = await contractDocxExportClient.dataToDocx(contractDocxExportId, data)
    downloadFile(url, `${contractName}.docx`)
    setLoading(false)
    onClose()
    return

  };
  const GenerateXlsxUrl = async () => {
    const workbook = serializeParamValues(inputValues, templateData, fileNames, beneficialsMap)
    saveWorkbook(workbook, `${contractName}.xlsx`)
    onClose()
  }



  return (
    <>
      <>
        <div id="contractz-lab" ref={modalref} className="contractz-lab-export" style={{ position: "absolute", top: "9vh", right: "35vh" }}>
          <div className="modal-dialog export-modal-specific">
            <div className="modal-content" style={{ width: "fit-content" }}>
              <div className="modal-header header-export" >
                <h5 className="modal-title export-title">{t("pages.editionContract.popups.export.title")}</h5>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body body-export">
                  <div className="form-group">
                    <p className="export-contract-text">{t("pages.editionContract.popups.export.description")}</p>
                    <div className="form-floating mb-3 export-buttons">
                      <button className="btn export-btn" onClick={GenerateXlsxUrl} style={{ margin: "10px 0px" }}>
                        <div className="csv-img"></div>
                        <p>{t("pages.editionContract.popups.export.exportCSV")}</p>
                      </button>

                      <>
                        <button className="btn export-btn" onClick={GenerateDocxUrl} style={{ margin: "10px 0px" }}>
                          <div className="word-img"></div>
                          <p>{t("pages.editionContract.popups.export.exportWord")}</p>
                        </button>
                      </>

                    </div>
                    <p className="export-contract-text">{t("pages.editionContract.popups.export.footer")}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ExportModal;
