import "../commonmodal.scss";
import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import Loading from "../../common/Loading";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { ContractTemplateEntity, FormEntity } from "../../../domain/entities";
import { FormClient } from "../../../services/api/FormClient";
import { toast } from "react-toastify";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./exportToContractModal.scss";
import AddBenificialModel from "../AddBeneficialModal";
import InputValuesContext from "../../../contexts/InputValuesContext";
import EditTableModal from "../../EditTableModal";
import { FormParam } from "../../../domain/types/FormParams";
import UnmatchedPopup from "./UnmatchedPopup";

interface ExportToContractModalType {
  onClose: () => void;
  form?: FormEntity;
  contractTemplate?: ContractTemplateEntity;
  contractName: string;
}
interface MatchingParam {
  contractParamName: string;
  formParamName?: string;
  formParamValue?: any;
  matched: boolean;
  formParamOptions?: string[];
}
function ExportToContractModal({
  onClose,
  form,
  contractTemplate,
  contractName,
}: ExportToContractModalType) {
  const { t, language } = useTranslation();
  const [loading, setLoading] = useState(false);
  const translationPath = t("modals.exportToContract");
  const [showBenificialModel, setShowBenificialModel] = useState(false);
  const [showTableModal, setShowTableModal] = useState(false);
  const [showUnmatchedPopup, setShowUnmatchedPopup] = useState(false);
  const [unmatchedParams, setUnmatchedParams] = useState<string[]>([]);
  const [error, setError] = useState("");
  const { inputValues } =useContext(InputValuesContext);
  const [matchingData, setMatchingData] = useState<MatchingParam[]>([]);
  const navigate = useNavigate();
  const apiClient = useApiClientWithLoading();
  const formClient = new FormClient(apiClient);
  const formPages = form?.template?.pages || [];
  const formParamValues = form?.paramValues || {};
  function getParamValue(
    param: FormParam,
    currentValue: any,
    form?: FormEntity
  ): any {

    if (!param) {
      console.error("Parameter is undefined or null:", param);
      return "";
    }
    const paramType = param.type;
    if (!paramType) {
      console.error("Parameter type is undefined or null:", paramType);
      return "";
    }
    switch (paramType) {
      case "date":
        return currentValue ? new Date(currentValue).toLocaleDateString() : "";
      case "beneficial":
        return (
          <>
            <button
              type="button"
              className="benificial-button"
              // onClick={(e) => {e.stopPropagation();setShowBenificialModel(true)}}
            >
              {param.label}
            </button>
            {/* {showBenificialModel && (<><AddBenificialModel
                onClose={() => setShowBenificialModel(false)}
                beneficialsMap={form.beneficialsMap}
                currentBenificialData={form.beneficialsMap[currentValue] || {}}
                onSave={() => {}}
                beneficialTypes={
                  param.args?.beneficialTypes ?? ["Company", "Person"]
                }
              />
              </>
            )} */}
          </>
        );
      case "table":
        const currentTableValues: (string | Date | number)[][] = inputValues?.[param.name] || [];
        return (
          <>
            <button
              type="button"
              className="benificial-button"
              // onClick={() => setShowTableModal(true)}
            >
              {param.label}
            </button>
            {/* {showTableModal && (
              <EditTableModal
                param={param}
                onClose={() => setShowTableModal(false)}
                currentTableValues={currentTableValues}
                onSave={() => {}}
              />
            )} */}
          </>
        );
      case "boolean":
        return <p>{currentValue === true ? t("pages.editionContract.yes") : t("pages.editionContract.no")}</p>;
      case "enum":
        const enumOptions = param.args?.map((arg) => arg.option);
        return <p>{enumOptions[currentValue]}</p>;
      case "list":
        const listOptions = param.args?.map((arg) => arg.option);
        return <p>{listOptions[currentValue]}</p>;
      case "file":
        return <p>{form?.fileNames[currentValue]}</p>;
      default:
        return currentValue || "";
    }
  }
    const contractClauses = contractTemplate?.clauses || [];
  useEffect(() => {
    if (form && contractTemplate) {
      const tempMatchingData: MatchingParam[] = [];

      contractClauses?.forEach((clause) => {
        clause.segmentation.segmentedParams?.forEach((contractParam) => {
          let matched = false;
          let matchedFormParam = null;

          formPages?.forEach((page) => {
            page.params?.forEach((formParam) => {
              if (
                formParam.type === contractParam.type &&
                formParam.name === contractParam.name
              ) {
                matched = true;
                matchedFormParam = formParam;
                tempMatchingData.push({
                  contractParamName: contractParam.name,
                  formParamName: formParam.name,
                  formParamValue: formParam&&getParamValue(
                    formParam,
                    formParamValues[formParam.name],
                    form
                  ),
                  matched: true,
                });
              }
            });
          });
          if (!matched) {
            const formParamOptions = formPages?.flatMap((page) =>
              page.params
                ?.filter((formParam) => formParam.type === contractParam.type)
                ?.map((formParam) => formParam.name)
            );

            tempMatchingData.push({
              contractParamName: contractParam.name,
              matched: false,
              formParamValue: "",
              formParamOptions,
            });
          }
        });
      });

      setMatchingData(tempMatchingData);
    }
  }, [form, contractTemplate]);

  const { handleSubmit, register } = useForm();

  const handleSelectChange = (index: number, newFormParamName: string) => {
    const updatedMatchingData = [...matchingData];
    const selectedFormParam = formPages?.flatMap((page) =>
      page.params.filter((formParam) => formParam.name === newFormParamName)
    )[0];

    if (selectedFormParam) {
      updatedMatchingData[index].formParamName = newFormParamName;
      updatedMatchingData[index].formParamValue = getParamValue(
        selectedFormParam,
        formParamValues[newFormParamName],
        form
      );

      updatedMatchingData[index].matched = true;
    } else {
      updatedMatchingData[index].formParamName = "";
      updatedMatchingData[index].formParamValue = "";
      updatedMatchingData[index].matched = false;
    }
    setError("")
    setMatchingData(updatedMatchingData);
  };
  const handleMigration = async (ignoreUnmatched: boolean) => {
    if (!ignoreUnmatched) {
      const allMatched = matchingData.every(item => item.matched);
      
      if (!allMatched) {
        const unmatched = matchingData
          .filter((item) => !item.matched)
          .map((item) => item.contractParamName);
  
        setUnmatchedParams(unmatched);
        setShowUnmatchedPopup(true);
        return;
      }
    }
    setLoading(true);
    try {
      const paramNameMap: Record<string, string> = {};
      matchingData?.forEach((item) => {
        if (item.formParamName) {
          paramNameMap[item.contractParamName] = item.formParamName;
        }
      });
  
      const response = await formClient.migrateToContract(
        form?.id,
        contractName,
        contractTemplate?.id,
        paramNameMap
      );
  
      toast.success(t("modals.exportToContract.successMessage"));
      onClose();
      navigate(`/edition-contrat/${response.row.id}`);
    } catch (error) {
      console.error("Error migrating to contract:", error);
      toast.error(t("modals.exportToContract.errorMessage"));
    }
    setLoading(false);
  };

  const onSubmit = () => handleMigration(false);
  const handleIgnore = () => handleMigration(true);
  return (
    <>
     <div className="modal-backdrop fade show"></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{translationPath.title}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language === "ar" ? "88%" : "" }}
                ></button>
              </div>
              <div className="modal-body modal-body-pilotage">
                {loading ? (
                  <Loading height="35vh" />
                ) : (
                  <form
                    className="form-group"
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ maxHeight: "550px"}}
                  >
                    <div className="form-input-content table-wrapper">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              {t(
                                "modals.exportToContract.fields.contractParamName"
                              )}
                            </th>
                            <th>
                              {t(
                                "modals.exportToContract.fields.formParamName"
                              )}
                            </th>
                            <th>
                              {t("modals.exportToContract.fields.formValue")}
                            </th>
                            <th>
                              {t("modals.exportToContract.fields.matched")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {matchingData?.map((item, index) => (
                            <tr key={index}>
                              <td>{item.contractParamName}</td>
                              <td>
                                <select
                                style={{width:"180px"}}
                                  {...register(`formParamName_${index}`)}
                                  value={item.formParamName || ""}
                                  onChange={(e) =>
                                    handleSelectChange(index, e.target.value)
                                  }
                                >
                                  {item.formParamName ? (
                                    <option
                                      key={item.formParamName}
                                      value={item.formParamName}
                                    >
                                      {item.formParamName}
                                    </option>
                                  ) : (
                                    <option value="">
                                      {t("modals.exportToContract.selectParam")}
                                    </option>
                                  )}

                                  {item.formParamOptions
                                    ?.filter(
                                      (option) => option !== item.formParamName
                                    )
                                    ?.map((option, idx) => (
                                      <option key={idx} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                </select>
                              </td>
                              <td>{item.formParamValue}</td>
                              <td>
                                {item.matched ? (
                                  <FaCheckCircle color="green" />
                                ) : (
                                  <FaTimesCircle color="red" />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <p className="error-msg">{error ? error :""}</p>
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-display"
                        style={{ left: "4.5%"}}
                      >
                        {t("pages.pilot.popups.contract.button")}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showUnmatchedPopup && (
        <UnmatchedPopup
          unmatchedParams={unmatchedParams}
          onIgnore={handleIgnore}
          onMatch={()=>setShowUnmatchedPopup(false)}
        />
      )}
    </>
  );
}
export default ExportToContractModal;
