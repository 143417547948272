import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { CustomPropertyEntity } from '../domain/entities/CustomProperty';
import useApiClientWithLoading from '../services/api/ApiClient';
import { useLocation } from 'react-router-dom';
import { CustomPropertyClient } from '../services/api/CustomPropertyClient';
import { groupBy, groupByUnique } from '../utils/array';
import { LoadingContext } from './LoadingContext';

export interface CustomPropertiesContextType {
  properties: CustomPropertyEntity[];
  setProperties: Dispatch<SetStateAction<CustomPropertyEntity[]>>;
  propertiesGroup: Record<CustomPropertyEntity['tag'], CustomPropertyEntity[]>;
  setPropertiesGroup: Dispatch<SetStateAction<Record<CustomPropertyEntity['tag'], CustomPropertyEntity[]>>>;
  tags: CustomPropertyEntity['tag'][];
  setTags: Dispatch<SetStateAction<CustomPropertyEntity['tag'][]>>;
  refresh();

}

const CustomPropertiesContext = createContext<CustomPropertiesContextType>({} as any);

export default CustomPropertiesContext;

export const CustomPropertiesProvider: React.FC<any> = ({ children }: { children: ReactNode }) => {

  const [properties, setProperties] = useState<CustomPropertyEntity[]>([]);
  const [propertiesGroup, setPropertiesGroup] = useState<Record<string, CustomPropertyEntity[]>>({});
  const [tags, setTags] = useState<CustomPropertyEntity['tag'][]>([]);

  const refresh = () => {
    customPropertyClient.getAll().then(({ rows }) => {
      setProperties(rows)
      const group = groupBy(rows, 'tag')
      for (const key in group) {
        group[key] = group[key].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      }
      setPropertiesGroup(group)
      setTags(Object.keys(group).sort())
    })
  }
  const location = useLocation()
  const projectContext: CustomPropertiesContextType = useMemo(
    () => ({
      properties,
      setProperties,
      propertiesGroup,
      setPropertiesGroup,
      tags,
      setTags,
      refresh,
    }),
    [properties, propertiesGroup, tags]
  );
  const apiClient = useApiClientWithLoading()
  const customPropertyClient = new CustomPropertyClient(apiClient)
  useEffect(() => {
    refresh()
    return () => {
    }
  }, [location.pathname])

  return (
    <CustomPropertiesContext.Provider value={projectContext}>
      {children}
    </CustomPropertiesContext.Provider>
  );
}