import React from "react";
import { FaTimesCircle } from "react-icons/fa"; 
import "./exportToContractModal.scss";
import { useTranslation } from "../../../contexts/TranslationProvider";

interface UnmatchedPopupProps {
  unmatchedParams: string[];
  onIgnore: () => void;
  onMatch: () => void;
}

const UnmatchedPopup: React.FC<UnmatchedPopupProps> = ({
  unmatchedParams,
  onIgnore,
  onMatch,
}) => {
  const { t } = useTranslation();
  const translationPath = "modals.exportToContract";

  return (
    <div className="unmatched-popup-backdrop">
      <div className="unmatched-popup-content">
        <div className="popup-header">
          <h2>{t(`${translationPath}.unmatchedTitle`)}</h2>
        </div>
        <p className="popup-description">
          {t(`${translationPath}.unmatchedDescription`)}
        </p>
        <ul className="unmatched-list">
          {unmatchedParams?.map((param, index) => (
            <li key={index}>
              {param} <span><FaTimesCircle className="fail-icon" /></span>
            </li>
          ))}
        </ul>
        <div className="popup-actions">
          <button className="btn-match" onClick={onMatch}>
            {t(`${translationPath}.matchAllButton`)}
          </button>
          <button className="btn-ignore" onClick={onIgnore}>
            {t(`${translationPath}.ignoreButton`)}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UnmatchedPopup;
