// MainContent.js
import { Navigate, Route, Routes } from "react-router-dom";

import "./pages/index.scss"
import React, { useContext } from "react";
import AdminPage from "./pages/AdminPage";
import ImportTypeLevels from "./pages/ImportTypeLevels";
import ListGroups from "./pages/ListGroups";
import ImportRulesPage from "./pages/ImportRules";
import ContractTemplatesPage from "./pages/ContractTemplates";
import UsersPage from "./pages/UsersPage";
import ContractTemplatePage from "./pages/ContractTemplate";
import FormTemplatePage from "./pages/FormTemplatePage";
import FormTemplatesPage from "./pages/FormTemplatesPage";
import ReglementsPage from "./pages/ReglementsPage";
import ReglementPage from "./pages/ReglementPage";
import PilotPage from "./pages/PilotPage";
import MyContracts from "./pages/MyContracts";
import MyRessources from "./pages/Ressources";
import GroupContracts from "./pages/GroupContracts";
import EditContractPage from "./pages/EditContractPage";
import EditFormPage from "./pages/Form";
import AiContractConfiguration from "./pages/AiContractConfiguration";
import ResponseConfiguration from "./pages/ResponseValidationUseCase";
import UseCasePage from "./pages/UseCasePage";
import DocumentPage from "./pages/Document";
import DocumentSummaryPage from "./pages/DocumentSummary";
import Analytics from "./pages/Analytics";
import ProjectList from "./pages/ProjectList";
import CreateProject from "./pages/CreateProject";
import Project from "./pages/Project";
import CreateDocument from "./pages/CreateProjectRequirement";
import ChatsPage from "./pages/ChatsPage";
import AskYourQuestions from "./pages/AskYourQuestions";
import ChatPage from "./pages/ChatPage";
import NotFound from "./pages/NotFound";
import LoadingTemplateProvider from "./contexts/LoadingTemplateContext";
import { AuthUserContext } from "./contexts/AuthUserContext";
import ProjectTemplates from "./pages/ProjectTemplates";
import CreateProjectTemplate from "./pages/CreateProjectTemplate";
import ProjectTemplate from "./pages/ProjectTemplate";
import EditProject from "./pages/EditProject";
import GroupProjects from "./pages/GroupProjects";
import OurDocuments from "./pages/OurDocuments";
import BeneficialsPage from "./pages/BeneficialsPage";
import CustomPropertyPage from "./pages/CustomPropertyPage";
import ContractDocxExportPage from "./pages/ContractDocxExportPage";

function MainContent() {
  const { activeRole } = useContext(AuthUserContext)

  return (
    <LoadingTemplateProvider>
      <div
        className="main-content"
      >

        <Routes>
          {activeRole == 'Admin' &&
            (<React.Fragment>
              <Route path="/admin" element={<AdminPage />} />
              <Route path="/admin/import-type-levels" element={<ImportTypeLevels />} />
              <Route path="/admin/list-groups" element={<ListGroups />} />
              <Route path="/admin/import-rules" element={<ImportRulesPage />} />
              <Route path="/contract-templates" element={<ContractTemplatesPage />} />
              <Route path="/users" element={<UsersPage />} />
              <Route path="/beneficials" element={<BeneficialsPage />} />
              <Route path="/properties" element={<CustomPropertyPage />} />
              <Route path="/contract-docx-exports" element={<ContractDocxExportPage />} />
              <Route path="/edition-contrat-template/:contractTemplateId?" element={<ContractTemplatePage />} />
              <Route path="/edition-form-template/:formTemplateId?" element={<FormTemplatePage />} />
              <Route path='/form-templates' element={<FormTemplatesPage />} />
              <Route path='/reglements' element={<ReglementsPage />} />
              <Route path="/edition-reglement/:reglementId?" element={<ReglementPage />} />
              <Route path="/admin/create-project-template" element={<CreateProjectTemplate />} />
              <Route path="/project-templates" element={<ProjectTemplates />} />
              <Route path="/edition-project-template/:projectTemplateId?" element={<ProjectTemplate />} />
            </React.Fragment>
            )
          }
          {activeRole == 'Client' &&
            (<React.Fragment>
              <Route path="/" element={<PilotPage />} />
              <Route path="/beneficials" element={<BeneficialsPage />} />

              <Route path="/contrats" element={<MyContracts />} />
              <Route path="/ressources" element={<MyRessources />} />
              <Route path="/group-contrats" element={<GroupContracts />} />
              <Route path="/group-projets" element={<GroupProjects />} />
              <Route path="/our-documents" element={<OurDocuments />} />
              <Route path="/edition-contrat/:contractId?" element={<EditContractPage />} />
              <Route path="/edit-form/:selectedFormId?" element={<EditFormPage />} />
              <Route path="/ai-edition-contrat/:contractId?" element={<AiContractConfiguration />} />
              <Route path="/ai-document-response/:usecaseId" element={<ResponseConfiguration />} />
              <Route path="/use-cases" element={<UseCasePage />} />
              <Route path="/document/:documentId?" element={<DocumentPage />} />
              <Route path="/document-summary/:documentId?" element={<DocumentSummaryPage />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/projets" element={<ProjectList />} />
              <Route path="/create-project/:projectId?" element={<CreateProject />} />
              <Route path="/edit-project/:projectId?" element={<EditProject />} />
              <Route path="/projet/:projectId?" element={<Project />} />
              <Route path="/createDocumentRequirement/:requirementId" element={<CreateDocument />} />
              <Route path="/chats" element={<ChatsPage />} />
              <Route path="/chat" element={<AskYourQuestions />} />
              <Route path="/chat-reglement/:reglementId" element={<ChatPage />} />
              <Route path="/openAi" element={<NotFound />} />
            </React.Fragment>
            )
          }
          {activeRole == 'CoContractor' &&
            (<React.Fragment>
              <Route path="/" element={<PilotPage />} />
              <Route path="/contrats" element={<MyContracts />} />
              <Route path="/ressources" element={<MyRessources />} />
              <Route path="/our-documents" element={<OurDocuments />} />
              <Route path="/edition-contrat/:contractId?" element={<EditContractPage />} />
              <Route path="/edit-form/:selectedFormId?" element={<EditFormPage />} />
              <Route path="/document/:documentId?" element={<DocumentPage />} />
              <Route path="/document-summary/:documentId?" element={<DocumentSummaryPage />} />

            </React.Fragment>
            )
          }
          <Route path="*" element={<Navigate to={activeRole == 'Admin' ? "/admin" : "/"} />} />
        </Routes>
      </div>
    </LoadingTemplateProvider>
  );
}

export default MainContent;
