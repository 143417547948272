import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import useApiClientWithLoading from "../services/api/ApiClient";
import { toast } from "react-toastify";
import { LoadingContext } from "../contexts/LoadingContext";
import { BeneficialEntity, FormEntity, FormTemplatePageEntity } from "../domain/entities";
import { EditFormContextType } from "../contexts/EditFormContext";
import { isFileType } from "../helpers/helper";
import { numberToEnumList } from "../domain/Project";
import { DocumentClient } from "../services/api/DocumentClient";
import { FormClient } from "../services/api/FormClient";
import { ProjectClient } from "../services/api/ProjectClient";
import useTranslation from "./useTranslation";
import { isObject } from "chart.js/dist/helpers/helpers.core";
import { BeneficialClient } from "../services/api/BeneficialClient";
import { mergeParamsForm } from "../helpers/ParseTemplateData";
import { LoadingRessourceContext } from "../contexts/LoadingRessourceContext";
import { getAllParams } from "../domain/FormTemplate";

export function useSubmitForm() {
  const { setLoading } = useContext(LoadingContext);
  const { setLoadingRessources } = useContext(LoadingRessourceContext)
  const navigate = useNavigate();
  const { t } = useTranslation();
  function isObject(value) {
    return value !== null && typeof value === "object";
  }
  const apiClient = useApiClientWithLoading(setLoading, "application/json");

  const submitForm = async (context: EditFormContextType) => {
    const {
      formId,
      name,
      paramValues,
      templateId,
      completionPercentage,
      setTemplate,
      setParamValues,
      setTemplateId,
      setGroups,
      setFormId,
      setForm,
      project,
      requirementType,
      requirement,
      template,
    } = context;
    const body: FormEntity = {
      templateId: templateId,
      paramValues: paramValues,
      status: completionPercentage === 100 ? "Done" : "Draft",
      name: name,
    };
    try {
      setLoadingRessources(prev => ([...prev, { type: "Form", id: formId }]))
      const documentClient = new DocumentClient(apiClient);
      const formClient = new FormClient(apiClient);
      const projectClient = new ProjectClient(apiClient);
      const beneficialClient = new BeneficialClient(apiClient);
      let paramValues = body.paramValues!;
      let hasError = false;
      const params = getAllParams(template)
      for (const param of params) {
        const key = param.name
        const value = paramValues[key];
        if (!value)
          continue
        switch (param.type) {
          case 'beneficial':
            if (isObject(value.data)) {
              console.log(`beneficial paramValues[${key}]`, paramValues[key]);
              let bData = value.data as BeneficialEntity
              let id = bData.id;
              bData.name =
                bData.type == "Person" && bData.firstName + " " + bData.lastName
                || bData.type == "Company" && bData.companyName
                || bData.type == "Minor" && bData.minorFirstName + " " + bData.minorLastName
                || bData.type == "Joint" && bData.firstName + " " + bData.lastName + "&" + bData.spouseFirstName + " " + bData.spouseLastName
                || bData.type == "Attorney" && bData.firstName + " " + bData.lastName

              let row = id
                ? await beneficialClient.update(id, bData)
                : (await beneficialClient.upsert(bData)).row;
              id = row.id;
              if (id) {
                paramValues[key] = id;
              }
            }
            break;
          case 'file':
            if (isObject(value)) {
              console.log(`File paramValues[${key}]`, paramValues[key]);
              const file = value;
              const name = file.name;
              try {
                const {
                  row: { id },
                } = await documentClient.upsertMultiForm({ file, name });
                paramValues[key] = id;
              } catch (error) {
                hasError = true;
                console.error(`Error uploading file for key ${key}:`, error);
                toast.error(`Error uploading file for ${key}`);
                break;
              }
            }
            break;
          default:
            break;
        }
      }

      body.paramValues = paramValues;

      let submitedFormId: FormEntity["id"];
      if (formId) {
        const row = await formClient.update(formId, body);
        submitedFormId = row.id;
        if (project) {
          navigate(`/projet/${project.id}`, { replace: true });
        } else {
          console.log("object")
          navigate("/ressources", { replace: true })
        }

        toast.success(t("pages.editionForm.updating.messages.success"));
      } else {
        const row = await formClient.create(body);
        submitedFormId = row.id;
        if (project) {
          const row = await projectClient.update(project?.id, {
            values: {
              ...project?.values,
              [requirement.id]: {
                type: numberToEnumList(requirementType)[0],
                id: submitedFormId,
              },
            },
            excludedRequirements: [...project.excludedRequirements],
          });
          navigate(`/projet/${row.id}`, { replace: true });
        }
        else {
          navigate("/ressources", { replace: true })
        }
        toast.success(t("pages.editionForm.creating.messages.success"));

      }
      setParamValues({});
      setTemplateId(null);
      setGroups([]);
      setTemplate(null);
      setFormId(null);
      setForm(null);
      setLoadingRessources(prev => prev.filter(r => r.id !== formId && r.type === "Form"))
      return submitedFormId;
    } catch (error) {
      console.error(error);
      setLoadingRessources(prev => prev.filter(r => r.id !== formId && r.type === "Form"))
      toast.error(
        formId
          ? t("pages.editionForm.updating.messages.error")
          : t("pages.contracts.creating.messages.error")
      );
    }
  };

  return submitForm;
}
