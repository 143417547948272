import { FormParam } from "./types/FormParams";

import { math } from "../utils/math"

import { SegmentedClauseParam } from "./types/ClauseParams";
export type CreateFormFromDocRequest = {
	file: Blob & { name: string };
	name: string;
	templateId: number;
}

export function validateFormula(formula: string, params: (FormParam | SegmentedClauseParam)[]): boolean {

	try {
		const data = {}

		params.forEach(p => {
			switch (p.type) {
				case 'boolean':
				case 'enum':
				case 'string':
				case 'number':
					data[p.name] = 0
					break;
				case 'date':
					data[p.name] = new Date().getTime()
					break;
				default:
					break;
			}
		})

		const compiledFormula = math.parse(formula);

		// Evaluate the formula with the given data as variables
		compiledFormula.evaluate(data);

		return true; // All symbols are valid
	} catch (error) {
		console.error('Error parsing formula:', error);
		return false; // Formula is invalid if there's an error in parsing
	}
}
export function validateConstraint(constraint: string, params: (FormParam | SegmentedClauseParam)[]): boolean {
	try {
		const data: { [key: string]: any } = {};

		// Prepare a mock data object with default values for each parameter
		params.forEach(p => {
			switch (p.type) {
				case 'boolean':
				case 'enum':
				case 'number':
					// Set mock number/boolean/enum values
					data[p.name] = 0;
					break;
				case 'date':
					// Set mock date value
					data[p.name] = new Date().getTime()
					break;
				case 'string':
					data[p.name] = ''
					break;
				default:
					break;
			}
		});
		// Parse the constraint to check if it's a valid expression
		const compiledConstraint = math.parse(constraint);

		// Try to evaluate it with mock data to ensure it can be executed
		compiledConstraint.evaluate(data);

		// If no error was thrown, the constraint is valid
		return true;
	} catch (error) {
		console.error('Error parsing constraint:', error);
		return false; // Return false if the constraint is invalid
	}
}

export function evaluateParamConstraints(param: FormParam | SegmentedClauseParam, data: { [key: string]: number | string | Date }, params: (FormParam | SegmentedClauseParam)[]): [string, boolean][] | null {
	if (!((param.type == 'number' || param.type == 'string' || param.type == 'date') && param.constraints)) {
		return null; // Return null if there's no formula
	}
	let evalData = {
		...data
	}
	params.forEach((param) => {
		const key = param.name
		if (!evalData[key]) {
			switch (param.type) {
				case 'boolean':
				case 'enum':
				case 'string':
				case 'number':
					evalData[key] = 0
					break;
				case 'date':
					evalData[key] = new Date().getTime()
					break;
				default:
					break;
			}
			return
		}
		if (param.type == 'boolean')
			evalData[key] = evalData[key] ? 1 : 0
		if (param.type == 'number')
			evalData[key] = parseFloat(evalData[key]?.toString()) ?? 0
		if (param.type == 'date')
			evalData[key] = new Date(evalData[key]).getTime()
	})

	const results: [string, boolean][] = param.constraints.map(constraint => {
		try {
			// Replace parameter names with values in the formula
			const compiledConstraint = math.parse(constraint.value);

			// Evaluate the formula with the given data as variables
			const result = compiledConstraint.evaluate(evalData);

			return [constraint.label, result];
		} catch (error) {
			console.error('Error evaluating constraint:', error);
			return [constraint.label, false];
		}
	});
	return results;
}

export function evaluateParamFormula(param: (FormParam | SegmentedClauseParam), data: { [key: string]: number | string | Date }, params: (FormParam | SegmentedClauseParam)[]): number | string | Date | null {
	if (!((param.type == 'number' || param.type == 'string' || param.type == 'date') && param.formula)) {
		return null; // Return null if there's no formula
	}
	let evalData = {
		...data
	}
	params.forEach((param) => {
		const key = param.name
		if (!param) return
		if (!evalData[key]) {
			switch (param.type) {
				case 'boolean':
				case 'enum':
				case 'string':
				case 'number':
					evalData[key] = 0
					break;
				case 'date':
					evalData[key] = new Date().getTime()
					break;
				default:
					break;
			}
			return
		}
		if (param.type == 'boolean')
			evalData[key] = evalData[key] ? 1 : 0
		if (param.type == 'number')
			evalData[key] = parseFloat(evalData[key]?.toString()) ?? 0
		if (param.type == 'date')
			evalData[key] = new Date(evalData[key]).getTime()
	})

	delete evalData[param.name]
	try {
		// Replace parameter names with values in the formula
		const compiledFormula = math.parse(param.formula);
		// Evaluate the formula with the given data as variables

		const result = compiledFormula.evaluate(evalData);

		if (param.type == 'number')
			return Number(result)
		if (param.type == 'date')
			return new Date(result)
		return result;
	} catch (error) {
		console.error('Error evaluating formula:', param.formula);
		console.error(error);
		console.warn({
			param,
			data
		});
		return Number.NaN;
	}
}
