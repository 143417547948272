import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import EditFormTemplateContext from "../../../../contexts/EditFormTemplateContext";
import { getAllParams } from '../../../../domain/FormTemplate';
import { validateFormula } from "../../../../domain/Form";
import { get } from "http";
import ConstraintRender from "./ConstraintRender";

function DefaultRender({
  param,
  value,
  valueName,
  isEditing,
  onChange,
  onChangeName,
  errors,
  onChangeFormula,
  onChangeConstraint,
  onDescriptionChange,
}) {
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const translationTypes = "pages.contractTemplateParam.params";
  const { formTemplate } = useContext(EditFormTemplateContext);
  const [filteredParams, setFilteredParams] = useState([]);
  const [filteredFormulas, setFilteredFormulas] = useState([]);

  useEffect(() => {
    const filteredParams = otherParams.filter((p) =>
      p.name.toLowerCase().includes(formulaInput?.toLowerCase())
    );
    const filteredFormulas = predefinedFormulas.filter((f) =>
      f.toLowerCase().includes(formulaInput?.toLowerCase())
    );
    setFilteredParams(filteredParams);
    setFilteredFormulas(filteredFormulas);
  }, []);
  const [formulaInput, setFormulaInput] = useState(param.formula ?? "");
  const [showDropDown, setShowDropDown] = useState(false);
  const params = getAllParams(formTemplate);
  const otherParams = useMemo(() => getAllParams(formTemplate)
    .filter(p => ['number', 'boolean', 'enum', 'date'].includes(p.type))
    .filter(p => p.name !== param.name), [formTemplate]);

  const unvalidFormula = useMemo(
    () => param.formula && !validateFormula(param.formula, otherParams),
    [param?.formula, otherParams]
  );
  const predefinedFormulas = ["LCM", "now", "dateDiffInYears", "calAge", "numberToLetters"];
  const formulaOperators = ["+", "-", "*", "/", ",", "(", ")", ":", "?"];
  const handleFormulaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    console.log(getAllParams(formTemplate))
    setFormulaInput(value);
    onChangeFormula(e);

    const lastPart = value
      .split(
        new RegExp(`[${formulaOperators.map((op) => "\\" + op).join("")}]`)
      )
      .pop()
      ?.trim();
    if (lastPart) {
      setFilteredParams(
        otherParams.filter((p) =>
          p.name.toLowerCase().includes(lastPart.toLowerCase())
        )
      );
      setFilteredFormulas(
        predefinedFormulas.filter((f) =>
          f.toLowerCase().includes(lastPart.toLowerCase())
        )
      );
    }
    console.log(lastPart)
    setShowDropDown(lastPart && lastPart.length > 0);
  };
  const replaceLastPart = (selectedValue: string) => {
    const regex = new RegExp(`([^${formulaOperators.join("\\")}]+)$`);
    const newFormula = formulaInput.replace(regex, selectedValue);
    handleFormulaChange({ target: { value: newFormula } } as any);
    setShowDropDown(false);
  };
  return (
    <div className={`input-param`}>
      <div className="input-type">{t(`${translationTypes}.${param.type}`)}</div>
      <div>
        {!isEditing ? (
          <label
            style={{ justifyContent: language === "ar" ? "right" : "left" }}
            className="custom-form-label"
          >
            {translationPath.parameterName} : {param.name}
          </label>
        ) : (
          <>
            <textarea
              className="custom-form-textarea"
              value={valueName}
              onChange={onChangeName}
              placeholder={translationPath.parameterName}
            />
            {errors.map((err, idx) => {
              if (err.where === "name") {
                return (
                  <div key={idx} className="error-message-dialog">
                    {err.why}
                  </div>
                );
              }
            })}
          </>
        )}
      </div>
      <div>
        {isEditing ? (
          <textarea
            className="custom-form-textarea"
            value={value}
            onChange={onChange}
            placeholder={translationPath.parameterLabel}
          />
        ) : (
          <div
            style={{ justifyContent: language === "ar" ? "right" : "left" }}
            className="custom-form-label"
          >
            {" "}
            {translationPath.parameterLabel} : {param.label}
          </div>
        )}
      </div>
      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterDescription} :{param.description ?? ""}</label>
        ) : (
          <textarea
            className="custom-form-textarea"
            value={param.description ?? ""}
            onChange={(e) => onDescriptionChange(e)}
            placeholder={translationPath.parameterDescription}
          />
        )}
      </div>
      {param.type === 'date' && (
        <>
          <ConstraintRender constraints={param.constraints} isEditing={isEditing} param={param} onChangeConstraint={onChangeConstraint} params={params} />
        </>
      )}
      {param.type === "string" && (
        <>
          {isEditing ? (
            <div style={{ position: "relative" }}>
              <textarea
                className="custom-form-textarea formula mt-2"
                value={formulaInput ? formulaInput : param.formula ?? ""}
                onChange={handleFormulaChange}
              />
              {showDropDown &&
                (filteredParams.length > 0 || filteredFormulas.length > 0) && (
                  <ul className="dropdown-list-formula">
                    {filteredParams.map((p, idx) => (
                      <li
                        key={idx}
                        onClick={() => {
                          replaceLastPart(p.name);
                          setShowDropDown(false);
                        }}
                      >
                        {p.name}
                      </li>
                    ))}
                    {filteredFormulas.map((f, idx) => (
                      <li
                        key={idx}
                        onClick={() => {
                          replaceLastPart(f);
                          setShowDropDown(false);
                        }}
                      >
                        {f}
                      </li>
                    ))}
                  </ul>
                )}
              {unvalidFormula && (
                <div className="error-message-dialog">
                  {translationPath.unvalidFormula}
                </div>
              )}
            </div>
          ) : (
            <div
              style={{ justifyContent: language === "ar" ? "right" : "left" }}
              className="custom-form-label"
            >
              {translationPath.parameterFormula} : {param.formula ?? ""}
            </div>
          )}
          <div>
            <ConstraintRender constraints={param.constraints} isEditing={isEditing} param={param} onChangeConstraint={onChangeConstraint} params={params} />
          </div>
        </>
      )}
    </div>
  );
}

export default DefaultRender;
