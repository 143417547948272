import { RenderSegments, SegmentedClauseParam, SegmentedText, SegmentedTextType } from "../domain/types/ClauseParams";
import { BeneficialCompanyEntity, BeneficialEntity, BeneficialMinorEntity, BeneficialPersonEntity, ContractEntity } from "../domain/entities";
import { getDeepValue } from "../utils/object";
import { fillData, pathHead } from "../utils/string";
import { isValidInputValue } from "./CompletionPercentage";
import { LanguageType } from "../contexts/TranslationProvider";
import { getBeneficialDescritption, virtualbeneficialGetters } from "./Beneficial";
import moment from "moment";

type Scope = {
	beneficialParamName?: string;
	beneficial?: BeneficialEntity;
}
export function getValueFromInputs<T extends SegmentedClauseParam>(inputPath: string, inputs: any,
	fileNames: ContractEntity['fileNames'], beneficialsMap: ContractEntity['beneficialsMap'],
	params: T[], scope?: Scope) {
	const [paramName, valuePath] = pathHead(inputPath)
	let parentParam = params.find((param) => param.name === paramName)
	let value = null
	if (parentParam) {
		switch (parentParam.type) {
			case 'boolean':
			case 'enum':
				const definitions = params.filter((param) => param.name === paramName && param.type == parentParam.type)
				parentParam = definitions.find((param) => ((param as any).definition || 0) === (Number(valuePath) || 0))
				value = inputs[paramName]
				break;
			case 'static-table':
				const table = params.find((param) => param.name === paramName)
				value = table
				break;
			case 'beneficial':
				const benecialId = inputs[paramName]
				value = beneficialsMap[benecialId]
				break;
			case 'beneficial[]':
				const benecialsId = inputs[paramName]
				value = Array.isArray(benecialsId) ? benecialsId.map(benecialId => beneficialsMap[benecialId])
					: undefined
				break;
			case 'file':
				if (inputs[paramName] instanceof File) {
					value = inputs[paramName].name
				} else {
					const fileId = inputs[paramName]
					value = fileNames[fileId]
				}
				break;
			default:
				value = inputs[paramName]
				break;
		}
	} else if (scope?.beneficial && Object.keys(scope.beneficial).includes(paramName)) {
		parentParam = params.find((param) => param.name === scope?.beneficialParamName)
		value = getDeepValue(scope.beneficial, inputPath)
		return { value, parentParam, scopeParam: true };

	}
	return { value, parentParam };
};
export function findParamBoundaries(renderSegments: RenderSegments, paramName: string): [number, number][] {
	const boundaries: [number, number][] = [];
	let paramStack: { index: number, paramName: string }[] = [];

	for (let i = 0; i < renderSegments.length; i++) {
		const segment = renderSegments[i];

		if (segment.type === SegmentedTextType.PARAM_START && segment.paramName === paramName) {
			paramStack.push({ index: i, paramName });
		} else if (segment.type === SegmentedTextType.PARAM_END && segment.paramName === paramName && paramStack.length > 0 && paramStack[paramStack.length - 1].paramName === paramName) {
			const start = paramStack.pop()!.index;
			boundaries.push([start, i]);
		}
	}

	return boundaries;
}

export function findParamsBoundaries(renderSegments: RenderSegments): [number, number][] {
	const boundaries: [number, number][] = [];
	let paramStack: { index: number, paramName: string }[] = [];

	for (let i = 0; i < renderSegments.length; i++) {
		const segment = renderSegments[i];

		if (segment.type === SegmentedTextType.PARAM_START) {
			paramStack.push({ index: i, paramName: segment.paramName });
		} else if (segment.type === SegmentedTextType.PARAM_END && paramStack.length > 0 && paramStack[paramStack.length - 1].paramName === segment.paramName) {
			const start = paramStack.pop()!.index;
			boundaries.push([start, i]);
		}
	}

	return boundaries;
}


export function getRenderSegments(segmentedText: SegmentedText, inputs: any,
	fileNames: ContractEntity['fileNames'], beneficialsMap: ContractEntity['beneficialsMap'],
	params: SegmentedClauseParam[], t: (key: any) => any, language: LanguageType, scope?: Scope): RenderSegments {
	let outputSegmentedText = [] as RenderSegments
	segmentedText.map((segment) => {
		switch (segment[2]) {
			case SegmentedTextType.STATIC:
				outputSegmentedText.push({
					type: SegmentedTextType.STATIC,
					id: segment[0],
					value: segment[1],
					style: segment[3]
				})
				break;
			case SegmentedTextType.PARAM:
				const inputPath = segment[1];
				const [paramName, valuePath] = pathHead(inputPath)
				const { value: input, parentParam, scopeParam } = getValueFromInputs(inputPath, inputs, fileNames, beneficialsMap, params, scope);
				if (!parentParam) {
					// console.warn("getRenderSegments: parentParam not found", inputPath, segment);
					return outputSegmentedText.push({
						type: SegmentedTextType.STATIC,
						id: segment[0],
						value: segment[1],
						style: segment[3]
					})
				}
				if (scopeParam) {
					return outputSegmentedText.push(
						{
							type: SegmentedTextType.PARAM_VALUE,
							id: segment[0],
							value: input,
							paramName: parentParam.name,
							style: segment[3]
						}
					)
				}
				if (!isValidInputValue(input)) {
					return outputSegmentedText.push({
						type: SegmentedTextType.PARAM,
						id: segment[0],
						value: segment[1],
						paramName: parentParam.name,
						style: segment[3]
					})
				}
				const defaultSegment = {
					type: SegmentedTextType.PARAM,
					id: segment[0],
					value: segment[1],
					paramName: parentParam.name,
					style: segment[3]
				}
				const startSegment = {
					type: SegmentedTextType.PARAM_START,
					id: `${defaultSegment.id}-START`,
					paramName: defaultSegment.paramName,
					value: " ",
					inputValue: input
				}
				const endSegment = {
					type: SegmentedTextType.PARAM_END,
					id: `${defaultSegment.id}-END`,
					paramName: defaultSegment.paramName,
					value: " ",
					inputValue: input
				}
				const startStaticTableSegment = {
					type: SegmentedTextType.STATIC_TABLE_START,
					id: `${defaultSegment.id}-START`,
					paramName: defaultSegment.paramName,
					value: " ",
					inputValue: input
				}
				switch (parentParam.type) {
					case 'beneficial':
						const beneficial = input as BeneficialEntity
						const fieldName = segment[1].split(".")[1] as keyof BeneficialPersonEntity | keyof BeneficialCompanyEntity | keyof BeneficialMinorEntity
						if (fieldName == 'address') {
							return outputSegmentedText.push(
								{
									type: SegmentedTextType.PARAM_VALUE,
									id: segment[0],
									value: beneficial.addressString,
									paramName: parentParam.name,
									style: segment[3]
								}
							)
						} else if (fieldName == 'description') {
							return outputSegmentedText.push({
								type: SegmentedTextType.PARAM_VALUE,
								id: segment[0],
								value: getBeneficialDescritption(beneficial, t, language),
								paramName: parentParam.name,
								style: segment[3]
							})
						}
						else if (fieldName == 'gender') {
							return outputSegmentedText.push({
								type: SegmentedTextType.PARAM_VALUE,
								id: segment[0],
								value: virtualbeneficialGetters.honorific(beneficial, t, language),
								paramName: parentParam.name,
								style: segment[3]
							})
						}
						else if (fieldName == 'minorGender') {
							return outputSegmentedText.push({
								type: SegmentedTextType.PARAM_VALUE,
								id: segment[0],
								value: t(`pages.editionContract.popups.benificial.genderPrefix.${beneficial?.gender}`),
								paramName: parentParam.name,
								style: segment[3]
							})
						}
						else if (fieldName == "nationality") {
							return outputSegmentedText.push({
								type: SegmentedTextType.PARAM_VALUE,
								id: segment[0],
								value: virtualbeneficialGetters.nationality(beneficial, t, language),
								paramName: parentParam.name,
								style: segment[3]
							})
						}
						else if (fieldName == "minorNationality") {
							return outputSegmentedText.push({
								type: SegmentedTextType.PARAM_VALUE,
								id: segment[0],
								value: virtualbeneficialGetters.minorNationality((beneficial) as BeneficialMinorEntity, t, language),
								paramName: parentParam.name
							})
						}
						else if (fieldName == "maritalStatus") {
							return outputSegmentedText.push({
								type: SegmentedTextType.PARAM_VALUE,
								id: segment[0],
								value: t(`pages.editionContract.popups.benificial.options.maritalStatusOptions.${beneficial?.maritalStatus}`),
								paramName: parentParam.name,
								style: segment[3]
							})
						}
						else if (fieldName == "socialCapitalCurrency") {
							return outputSegmentedText.push({
								type: SegmentedTextType.PARAM_VALUE,
								id: segment[0],
								value: virtualbeneficialGetters.socialCapitalCurrency(beneficial as any, t, language),
								paramName: parentParam.name,
								style: segment[3]
							})
						}
						const value = getDeepValue(beneficial, valuePath)
						if (!isValidInputValue(value)) {
							return outputSegmentedText.push({
								type: SegmentedTextType.PARAM,
								id: segment[0],
								value: segment[1],
								paramName: parentParam.name,
								style: segment[3]
							})
						}
						if (fieldName == 'cinDeliveryDate'
							|| fieldName == 'dateOfBirth'
							|| fieldName == 'createdAt'
							|| fieldName == 'updatedAt'
							|| fieldName == 'minorDateOfBirth'
							|| fieldName == 'minorOrderDate'
						) {
							return outputSegmentedText.push({
								type: SegmentedTextType.PARAM_VALUE,
								id: segment[0],
								value: moment(new Date(value)).format(language == 'ar' ? "YYYY/MM/DD" : "DD/MM/YYYY"),
								paramName: parentParam.name,
								style: segment[3]
							})
						}
						return outputSegmentedText.push({
							type: SegmentedTextType.PARAM_VALUE,
							id: segment[0],
							value: String(value),
							paramName: parentParam.name,
							style: segment[3]
						})
					case 'beneficial[]':
						const BeneficialListFieldName = segment[1].split(".")[1] as keyof BeneficialPersonEntity | keyof BeneficialCompanyEntity | keyof BeneficialMinorEntity
						const beneficials: BeneficialEntity[] = Array.isArray(input) && input.filter(b => b) || []

						if (beneficials.length) {
							return beneficials.forEach((beneficial: BeneficialEntity) => {
								outputSegmentedText.push({
									type: SegmentedTextType.STATIC,
									id: `${segment[0]}`,
									value: "\n",
								})
								if (BeneficialListFieldName == 'address') {
									return outputSegmentedText.push(
										{
											type: SegmentedTextType.PARAM_VALUE,
											id: segment[0],
											value: beneficial.addressString,
											paramName: parentParam.name,
											style: segment[3]
										}
									)
								} else if (BeneficialListFieldName == 'description') {
									return outputSegmentedText.push({
										type: SegmentedTextType.PARAM_VALUE,
										id: segment[0],
										value: getBeneficialDescritption(beneficial, t, language),
										paramName: parentParam.name,
										style: segment[3]
									})
								}
								else if (BeneficialListFieldName == 'gender') {
									return outputSegmentedText.push({
										type: SegmentedTextType.PARAM_VALUE,
										id: segment[0],
										value: virtualbeneficialGetters.honorific(beneficial, t, language),
										paramName: parentParam.name,
										style: segment[3]
									})
								}
								else if (BeneficialListFieldName == 'minorGender') {
									return outputSegmentedText.push({
										type: SegmentedTextType.PARAM_VALUE,
										id: segment[0],
										value: t(`pages.editionContract.popups.benificial.genderPrefix.${beneficial?.gender}`),
										paramName: parentParam.name,
										style: segment[3]
									})
								}
								else if (BeneficialListFieldName == "nationality") {
									return outputSegmentedText.push({
										type: SegmentedTextType.PARAM_VALUE,
										id: segment[0],
										value: virtualbeneficialGetters.nationality(beneficial, t, language),
										paramName: parentParam.name,
										style: segment[3]
									})
								}
								else if (BeneficialListFieldName == "maritalStatus") {
									return outputSegmentedText.push({
										type: SegmentedTextType.PARAM_VALUE,
										id: segment[0],
										value: t(`pages.editionContract.popups.benificial.options.maritalStatusOptions.${beneficial?.maritalStatus}`),
										paramName: parentParam.name,
										style: segment[3]
									})
								}
								else if (BeneficialListFieldName == "socialCapitalCurrency") {
									return outputSegmentedText.push({
										type: SegmentedTextType.PARAM_VALUE,
										id: segment[0],
										value: virtualbeneficialGetters.socialCapitalCurrency(beneficial as any, t, language),
										paramName: parentParam.name,
										style: segment[3]
									})
								}
								const value = getDeepValue(beneficial, valuePath)
								if (!isValidInputValue(value)) {
									return outputSegmentedText.push({
										type: SegmentedTextType.PARAM,
										id: segment[0],
										value: segment[1],
										paramName: parentParam.name,
										style: segment[3]
									})
								}
								if (BeneficialListFieldName == 'cinDeliveryDate'
									|| BeneficialListFieldName == 'dateOfBirth'
									|| BeneficialListFieldName == 'createdAt'
									|| BeneficialListFieldName == 'updatedAt'
									|| BeneficialListFieldName == 'minorDateOfBirth'
									|| BeneficialListFieldName == 'minorOrderDate'
								) {
									return outputSegmentedText.push({
										type: SegmentedTextType.PARAM_VALUE,
										id: segment[0],
										value: moment(new Date(value)).format(language == 'ar' ? "YYYY/MM/DD" : "DD/MM/YYYY"),
										paramName: parentParam.name,
										style: segment[3]
									})
								}
								return outputSegmentedText.push({
									type: SegmentedTextType.PARAM_VALUE,
									id: segment[0],
									value: String(value),
									paramName: parentParam.name,
									style: segment[3]
								})

							}
							)
						}
						return outputSegmentedText.push(defaultSegment)
					case 'boolean':
						return outputSegmentedText.push(
							startSegment,
							...getRenderSegments(input ? parentParam.args.textIfTrue : parentParam.args.textIfFalse,
								inputs, fileNames, beneficialsMap, params, t, language, scope),
							endSegment
						)

					case 'date':
						const reformattedDate = moment(new Date(input)).format(language == 'ar' ? "YYYY/MM/DD" : "DD/MM/YYYY")

						return outputSegmentedText.push(
							{
								type: SegmentedTextType.PARAM_VALUE,
								id: segment[0],
								value: reformattedDate,
								paramName: parentParam.name,
								style: segment[3]
							}
						)
					case 'file':
						return outputSegmentedText.push({
							type: SegmentedTextType.PARAM_VALUE,
							id: segment[0],
							value: input,
							paramName: parentParam.name,
							style: segment[3]
						})
					case 'comment':
						return outputSegmentedText.push({
							type: SegmentedTextType.PARAM_COMMENT_VALUE,
							id: segment[0],
							value: input,
							paramName: parentParam.name,
							style: segment[3]
						})
					case 'enum':
						const arg = parentParam.args[input]
						if (!arg)
							return outputSegmentedText.push(defaultSegment)
						const segmentedText = arg.text;
						try {
							return outputSegmentedText.push(
								startSegment,
								...getRenderSegments(segmentedText, inputs, fileNames, beneficialsMap, params, t, language, scope),
								endSegment,
							)
						} catch (error) {
							console.error({ input, segmentedText });
							throw error;
						}
					case 'static-table':
						const tableArg = parentParam.args;
						if (!tableArg.cells || !tableArg.cells.length) {
							return outputSegmentedText.push(defaultSegment);
						}

						// Start of the static table
						outputSegmentedText.push({
							type: SegmentedTextType.STATIC_TABLE_START,
							id: `${defaultSegment.id}-TABLE-START`,
							paramName: defaultSegment.paramName,
							value: " ",
							inputValue: input
						});

						const cells = tableArg.cells;

						cells.forEach((row, rowIndex) => {
							// Start of the row
							outputSegmentedText.push({
								type: SegmentedTextType.STATIC_TABLE_ROW_START,
								id: `${defaultSegment.id}-ROW-${rowIndex}-START`,
								paramName: defaultSegment.paramName,
								value: " "
							});

							row.forEach((cell, cellIndex) => {
								// Start of the cell
								outputSegmentedText.push({
									type: SegmentedTextType.STATIC_TABLE_CELL_START,
									id: `${defaultSegment.id}-ROW-${rowIndex}-CELL-${cellIndex}-START`,
									paramName: defaultSegment.paramName,
									value: " "
								});

								const cellValue = cell.content;
								if (cellValue) {
									// Recursively handle nested segments within the cell
									cellValue.forEach((content) => {
										outputSegmentedText.push(...getRenderSegments(
											[content], inputs, fileNames, beneficialsMap, params, t, language, scope
										));
									});
								}

								// End of the cell
								outputSegmentedText.push({
									type: SegmentedTextType.STATIC_TABLE_CELL_END,
									id: `${defaultSegment.id}-ROW-${rowIndex}-CELL-${cellIndex}-END`,
									paramName: defaultSegment.paramName,
									value: " "
								});
							});

							// End of the row
							outputSegmentedText.push({
								type: SegmentedTextType.STATIC_TABLE_ROW_END,
								id: `${defaultSegment.id}-ROW-${rowIndex}-END`,
								paramName: defaultSegment.paramName,
								value: " "
							});
						});

						// End of the static table
						outputSegmentedText.push({
							type: SegmentedTextType.STATIC_TABLE_END,
							id: `${defaultSegment.id}-TABLE-END`,
							paramName: defaultSegment.paramName,
							value: " ",
							inputValue: input
						});

						return outputSegmentedText;

					case 'list':
						return outputSegmentedText.push((Array.isArray(input)) ?
							{
								type: SegmentedTextType.PARAM_VALUE,
								id: segment[0],
								value: input.map(idx => parentParam.args[idx]?.option).join("\n"),
								paramName: parentParam.name,
								style: segment[3]
							}
							: defaultSegment)
					case 'table':
						const headers = parentParam.args.map(arg => arg.header)
						return outputSegmentedText.push(
							{
								type: SegmentedTextType.PARAM_TABLE_VALUE,
								id: segment[0],
								value: JSON.stringify([!!parentParam.transposed, [headers, ...input]]),
								paramName: parentParam.name,
								style: segment[3]
							}
						)
					case 'csv':
						return outputSegmentedText.push((Array.isArray(input)) ?
							{
								type: SegmentedTextType.PARAM_TABLE_VALUE,
								id: segment[0],
								value: JSON.stringify([input[0], input[1]]),
								paramName: parentParam.name,
								style: segment[3]
							}
							: defaultSegment)
					case 'string':
					case 'property':
					case 'number':
						return outputSegmentedText.push(
							{
								type: SegmentedTextType.PARAM_VALUE,
								id: segment[0],
								value: String(input),
								paramName: parentParam.name,
								style: segment[3]
							})
					default:
						console.warn(parentParam);
						console.warn(input);
						console.warn(segment);
						return
				}
			case SegmentedTextType.COMMENT:
				return outputSegmentedText.push({
					type: SegmentedTextType.COMMENT,
					id: segment[0],
					value: segment[1],
					style: segment[3]
				})
			default:
				break;
		}

	})

	return outputSegmentedText
}