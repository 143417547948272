import React, {useEffect} from 'react';
import RenderTemplateSingleSegmentedText from './RenderTemplateSingleSegmentedText';
import { SegmentedTextType } from '../../../domain/types/ClauseParams';

function RenderTemplateSegmentedTable({ tableSegments, segmentation, segmentsOverrides, changedParamBoundaries, isSelected }) {
    const rows = [];
    let currentRow = null;
    const cells = [];
    let currentCell = null;

    tableSegments.forEach(segment => {
        const { type, id, value } = segment;

        // Start a new row
        if (type === SegmentedTextType.STATIC_TABLE_ROW_START) {
            currentRow = []; // Initialize a new row
        }
        
        // Add cells to the current row
        if (type === SegmentedTextType.STATIC_TABLE_CELL_START) {
            currentCell = []; // Initialize a new cell
        }

        // Add content to the current cell
        if (type === SegmentedTextType.STATIC || type === SegmentedTextType.PARAM || type === SegmentedTextType.COMMENT) {
            currentCell.push(segment);
        }

        // End the cell
        if (type === SegmentedTextType.STATIC_TABLE_CELL_END) {
            if (currentCell) {
                currentRow.push(currentCell); 
                currentCell = null; 
            }
        }
        
        // End the row
        if (type === SegmentedTextType.STATIC_TABLE_ROW_END) {
            if (currentRow) {
                rows.push(currentRow); 
                currentRow = null; 
            }
        }
    });

    return (
        <table className="segmented-table" style={{ borderCollapse: 'collapse', width: '100%' }}>
            <tbody>
                {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.map((cellSegments, cellIndex) => (
                            <td key={cellIndex} style={{ border: '1px solid black', padding: '8px' }}>
                                {cellSegments.map((segment) => (
                                  <>
                                   <RenderTemplateSingleSegmentedText
                                        key={segment.id}
                                        idx={(segment as any).actualIdx} // Unique index for each segment
                                        segment={segment}
                                        isSelected={isSelected} // Adjust as needed
                                        segmentation={segmentation}
                                        changedParamBoundaries={changedParamBoundaries}
                                        segmentsOverrides={segmentsOverrides}
                                    />
                                  </>
                                ))}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default RenderTemplateSegmentedTable;
