import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "../../contexts/TranslationProvider";
import useApiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";
import { AiFillDelete, AiFillEdit, AiFillPlusSquare, AiFillSave } from "react-icons/ai";
import "./customPropertyPage.scss";
import { toast } from "react-toastify";
import { CustomPropertyEntity } from "../../domain/entities/CustomProperty";
import { CustomPropertyClient } from "../../services/api/CustomPropertyClient";
import CustomPropertiesContext from "../../contexts/CustomPropertiesContext";
import { LoadingContext } from "../../contexts/LoadingContext";


function CustomPropertyPage() {
  const { t, language } = useTranslation();
  const [newProperty, setNewProperty] =
    useState<CustomPropertyEntity | null>({
      tag: "",
      value: "",
    });
  const [editProperty, setEditProperty] = useState<CustomPropertyEntity | null>();
  const [editTag, setEditTag] = useState<{
    idx: number;
    value: string
  } | null>(null);

  const [newTagValues, setNewTagValues] = useState<Record<CustomPropertyEntity['tag'], CustomPropertyEntity['value']>>({})

  const customPropertyClient = new CustomPropertyClient(useApiClientWithLoading());

  const { isLoading, setLoading } = useContext(LoadingContext)

  const {
    propertiesGroup, setPropertiesGroup,
    tags, setTags,
    refresh,

  } = useContext(CustomPropertiesContext)
  useEffect(() => {
    tags.forEach((tag) => {
      newTagValues[tag] = ""
    })
    setNewTagValues(newTagValues)
    return () => { }
  }, [tags])


  const HandleDeleteProperty = async (id) => {
    try {
      setLoading(true);
      const { deleted } = await customPropertyClient.delete(id);
      if (deleted) {
        toast.success(t("pages.properties.popup.messages.successDelete"))
        setLoading(false);
        refresh()
      } else {
        throw ""
      }
    } catch (err) {
      toast.error(t("pages.properties.popup.messages.errorDelete"))
      setLoading(false);
    }
  }

  const handleCreateNewProperty = async () => {
    try {
      setLoading(true);
      await customPropertyClient.create(newProperty);
      toast.success(t("pages.properties.popup.messages.successCreate"))
      setNewProperty({})
      setLoading(false);
      refresh()
    } catch (err) {
      toast.error(t("pages.properties.popup.messages.errorCreate"))
      setLoading(false);
    }
  }

  const handleCreateNewTagProperty = async (tag: CustomPropertyEntity['tag']) => {
    try {
      if (!newTagValues[tag])
        return
      setLoading(true);
      await customPropertyClient.create({
        tag,
        value: newTagValues[tag]
      });
      toast.success(t("pages.properties.popup.messages.successCreate"))
      newTagValues[tag] = ""
      setNewTagValues({
        ...newTagValues
      })
      setLoading(false);
      refresh()
    } catch (err) {
      toast.error(t("pages.properties.popup.messages.errorCreate"))
      setLoading(false);
    }
  }
  const handleSaveEditProperty = async (toUpdate: CustomPropertyEntity) => {
    try {
      setLoading(true);
      await customPropertyClient.update(toUpdate.id, editProperty);
      toast.success(t("pages.properties.popup.messages.successUpdate"))
      setEditProperty(null)
      setLoading(false);
      refresh()
    } catch (err) {
      toast.error(t("pages.properties.popup.messages.errorUpdate"))
      setLoading(false);
    }
  }
  const handleSaveEditTag = async () => {
    try {
      if (!editTag)
        return
      const oldTag = tags[editTag.idx]
      const newTag = editTag?.value
      const properties = propertiesGroup[oldTag]
      setLoading(true);
      for (const cp of properties) {
        await customPropertyClient.update(cp.id, { tag: newTag });
      }
      toast.success(t("pages.properties.popup.messages.successUpdate"))
      setEditTag(null)
      setLoading(false);
      refresh()
    } catch (err) {
      toast.error(t("pages.properties.popup.messages.errorUpdate"))
      setLoading(false);
    }
  }


  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <section className="beneficials-list w-100">
          <span className="current-pathname">
            {t("pages.properties.title")}
          </span>
          <div className="table-container">
            <table className={`fixed-header-table ${language === "ar" && "arabic-table"}`}>
              <thead>
                <tr>
                  <th>{t("pages.properties.tag")}</th>
                  <th>{t("pages.properties.values")}</th>
                  <th>{t("pages.properties.actions")}</th>
                </tr>
              </thead>
              <tbody>
                <tr className="data-table-types">
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "2px",
                      textAlign: "center",
                    }}
                  >
                    <input
                      value={newProperty.tag}
                      onChange={(e) => {
                        setNewProperty({
                          ...newProperty,
                          tag: e.target.value
                        })
                      }}
                    />
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "2px",
                      textAlign: "center",
                    }}
                  >
                    <input
                      value={newProperty.value}
                      onChange={(e) => {
                        setNewProperty({
                          ...newProperty,
                          value: e.target.value
                        })
                      }}
                    />
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "2px",
                      textAlign: "center",
                    }}
                  >
                    <AiFillPlusSquare
                      className="delete-property"
                      style={{
                        color: "#2f14e5",
                        cursor: "pointer",
                        marginTop: "1px",
                        fontSize: "18px",
                      }}
                      onClick={handleCreateNewProperty}
                    />
                  </td>
                </tr>
                {tags.map((tag, idx) => {
                  const properties = propertiesGroup[tag]
                  let rowSpan = 2 + properties.length;
                  const isTagEditing = idx == editTag?.idx
                  return (<React.Fragment key={`tag_${tag}`}>
                    <tr className="data-table-types">
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "2px",
                          textAlign: "center",
                        }}
                        rowSpan={rowSpan}
                        colSpan={1}
                      >
                        {isTagEditing && (
                          <input
                            value={editTag.value}
                            onChange={(e) => {
                              setEditTag({
                                ...editTag,
                                value: e.target.value
                              })
                            }}
                          />
                        ) || (tag ?? <i>NULL</i>)}
                        {isTagEditing ? <AiFillSave
                          style={{
                            color: "#2f14e5",
                            cursor: "pointer",
                            marginTop: "1px",
                            fontSize: "18px",
                          }}
                          onClick={() => handleSaveEditTag()}
                        />
                          : <AiFillEdit
                            style={{
                              color: "#2f14e5",
                              cursor: "pointer",
                              marginTop: "1px",
                              fontSize: "18px",
                            }}
                            onClick={() => {
                              setEditTag({
                                idx,
                                value: tag
                              })
                            }}
                          />
                        }

                      </td>
                    </tr>
                    {properties.map((cp) => {
                      const isEditing = cp.id == editProperty?.id
                      return (
                        <React.Fragment
                          key={`tag_value${cp.id}`}
                        >
                          <tr className="data-table-types">
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "2px",
                                textAlign: "center",
                              }}
                            >
                              {isEditing ? (
                                <input
                                  value={editProperty.value}
                                  onChange={(e) => {
                                    setEditProperty({
                                      ...editProperty,
                                      value: e.target.value
                                    })
                                  }}
                                />
                              )
                                : cp?.value ?? (<i>NULL</i>)
                              }
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "2px",
                                textAlign: "center",
                              }}
                            >
                              {isEditing ? <AiFillSave
                                style={{
                                  color: "#2f14e5",
                                  cursor: "pointer",
                                  marginTop: "1px",
                                  fontSize: "18px",
                                }}
                                onClick={() => handleSaveEditProperty(cp)}
                              />
                                : <AiFillEdit
                                  style={{
                                    color: "#2f14e5",
                                    cursor: "pointer",
                                    marginTop: "1px",
                                    fontSize: "18px",
                                  }}
                                  onClick={() => {
                                    setEditProperty(cp)
                                  }}
                                />
                              }
                              <AiFillDelete
                                className="delete-property"
                                style={{
                                  color: "#e5142f",
                                  cursor: "pointer",
                                  marginTop: "1px",
                                  fontSize: "18px",
                                }}
                                onClick={() => {
                                  HandleDeleteProperty(cp.id)
                                }}
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                    <tr className="data-table-types" key={tag}>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "2px",
                          textAlign: "center",
                        }}
                      >
                        <input
                          value={newTagValues[tag]}
                          onChange={(e) => {
                            newTagValues[tag] = e.target.value
                            setNewTagValues({
                              ...newTagValues,
                            })
                          }}
                        />
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "2px",
                          textAlign: "center",
                        }}
                      >
                        <AiFillPlusSquare
                          className="delete-property"
                          style={{
                            color: "#2f14e5",
                            cursor: "pointer",
                            marginTop: "1px",
                            fontSize: "18px",
                          }}

                          onClick={() => handleCreateNewTagProperty(tag)}
                        />
                      </td>
                    </tr>
                  </React.Fragment>)
                })}
              </tbody>
            </table>
          </div>
          <div className="button-add-new-beneficial">
            <div className="vertical-line me-3"></div>
          </div>
        </section>
      )}
    </>
  );
}

export default CustomPropertyPage;
