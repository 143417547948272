import { getClauseName, getSubClauseName } from "../helpers/ParseTemplateData";
import { getRenderSegments } from "../helpers/segmentation";
import { transpose } from "../utils/array";
import { ClauseEntity, ContractEntity, ContractTemplateEntity, SubClauseEntity } from "./entities";
import { RenderSegments, SegmentedTextType, segmentedTextStyle } from "./types/ClauseParams";


export type ContractExportText = {
	name: string;
	preambule?: {
		name: string;
		text: string;
		subClauses: ({
			name: string;
			text: string;
		})[];
	};
	clauses: ({
		name: string;
		text: string;
		subClauses: ({
			name: string;
			text: string;
		})[];
	})[];
	signature?: {
		name: string;
		text: string;
		subClauses: ({
			name: string;
			text: string;
		})[];
	};
}

export function getContractExportData(contract: ContractEntity, t: (str: string) => string): ContractExportText {
	const template: ContractTemplateEntity = contract.template
	let clauses = template.clauses.filter((clause) => !contract.excludedClauses.includes(clause.id))
	clauses.forEach((clause) => {
		clause.subClauses = clause.subClauses.filter((subClause) => !contract.excludedSubClauses.includes(subClause.id))
	})

	let textExport: ContractExportText = {
		name: contract.name!,
		clauses: clauses?.map((clause, clauseIndex) => {
			const renderSegments = getRenderSegments(clause.segmentation?.segmentedText!, contract.paramValues, contract.fileNames, contract.beneficialsMap, clause.segmentation?.segmentedParams!, t, 'fr')
			const text = renderSegmentsToDocXml(contract, clause, renderSegments)
			return {
				name: getClauseName(template, clause, clauseIndex),
				text: text,
				subClauses: clause.subClauses?.map((subClause, subClauseIndex) => {
					const renderSegments = getRenderSegments(subClause.segmentation?.segmentedText!, contract.paramValues, contract.fileNames, contract.beneficialsMap, subClause.segmentation?.segmentedParams!, t, 'fr')
					const text = renderSegmentsToDocXml(contract, subClause, renderSegments)
					return {
						name: getSubClauseName(template, clause, subClause, clauseIndex, subClauseIndex),
						text: text,
					}
				}) ?? []
			}
		}) ?? []
	}
	return textExport
}
const applyStyles = (segmentStyle: segmentedTextStyle) => {
	let styleXml = '<w:rPr>';

	if (segmentStyle?.bold) {
		styleXml += '<w:b/>';
	} else {
		styleXml += '<w:b w:val="0"/>';
	}

	if (segmentStyle?.italic) {
		styleXml += '<w:i/>';
	}

	if (segmentStyle?.underline) {
		styleXml += '<w:u w:val="single"/>';
	}

	styleXml += '</w:rPr>';
	return styleXml;
};

export function renderSegmentsToDocXml(contract: ContractEntity, clause: ClauseEntity | SubClauseEntity, renderSegments: RenderSegments) {
	{
		const text = renderSegments?.map((segment, idx) => {
			const { id, value, type, style } = segment
			const text = contract.segmentsOverrides?.[id] ?? value
			const sanitizedText = text?.replaceAll(/\[\s*\]/g, " ")
			if (idx < 5) {
				console.log("segment", segment)
				console.log("style", style)
			}
			switch (type) {
				case SegmentedTextType.STATIC:
					return `${applyStyles(style)}<w:t xml:space="preserve">${sanitizedText || ""}</w:t>`
				case SegmentedTextType.COMMENT:
					return ""
				case SegmentedTextType.PARAM:
					const paramPath = value.split(".");
					const paramLabel = clause.segmentation?.segmentedParams.find((param) => param.name == segment.paramName)?.label;
					return `<w:rPr><w:b/></w:rPr><w:t xml:space="preserve">${paramLabel ?? value}${paramPath[1] ? `(${paramPath[1]})` : ""}</w:t>`
				case SegmentedTextType.PARAM_VALUE:
					return `<w:rPr><w:b/></w:rPr><w:t xml:space="preserve">${sanitizedText || ""}</w:t>`
				case SegmentedTextType.PARAM_TABLE_VALUE:
					let [transposed, tableRows] = JSON.parse(value) as [boolean, string[][]]
					if (transposed) {
						tableRows = transpose(tableRows)
					}
					let [headers, ...rows] = tableRows as string[][]
					return `</w:r></w:p>
		<w:tbl>
			<w:tblPr>
				<w:tblStyle w:val="TableGrid" />
				<w:tblW w:w="0" w:type="auto" />
				<w:tblLook w:val="04A0" w:firstRow="1" w:lastRow="0" w:firstColumn="1" w:lastColumn="0" w:noHBand="0" w:noVBand="1" />
			</w:tblPr>
			<w:tblGrid>
				${'<w:gridCol w:w="4601" />'.repeat(tableRows[0]?.length ?? 1)}
			</w:tblGrid>
			${tableRows.map((row) =>
						`<w:tr w:rsidR="007667F4" w14:paraId="4F5EFE64" w14:textId="77777777" w:rsidTr="007667F4">
					${row.map((cell) =>
							`<w:tc>
					<w:tcPr>
						<w:tcW w:w="4601" w:type="dxa" />
					</w:tcPr>
					<w:p w14:paraId="27D15200" w14:textId="7A92AB9E" w:rsidR="007667F4" w:rsidRDefault="007667F4" w:rsidP="005517FD">
						<w:pPr>
							<w:rPr>
							</w:rPr>
						</w:pPr>
						<w:r>
							<w:rPr>${""
							//cell style
							}
							</w:rPr>
							<w:t>${cell}</w:t>
						</w:r>
					</w:p>
				</w:tc>`).join("")}
						</w:tr>`).join("")}
		</w:tbl>
		<w:p><w:r>`.replaceAll(/>\n\s*</g, "><")
					return ""
				case SegmentedTextType.PARAM_COMMENT_VALUE:
					return ""
				default:
					return ""
			}
		}).join("")
		return text
	}
}