import { ContractTemplateParsedData, mergeParams, mergeParamsForm } from "../helpers/ParseTemplateData";
import { BeneficialEntity, ContractEntity } from "../domain/entities";
import { cellsToXLSXWorkbook } from "../utils/csv";

export function serializeParamValues(
	inputValues: ContractEntity['paramValues'],
	templateData: ContractTemplateParsedData,
	fileNames: Record<number, string>,
	beneficialsMap: Record<string, BeneficialEntity>
) {
	const params = mergeParams(templateData)

	let spreadsheets: Record<string, string[][]> = {
		"default": [
			['Clause_name', 'Sub_clause_code', 'Sub_clause_name', 'Name', 'Type', 'Label', 'Value'],
		],
	}
	let paramMap: Record<string, boolean> = {}
	let tableCounter = 1
	for (const param of params) {
		if (paramMap[param.name]) {
			continue
		}
		paramMap[param.name] = true
		const value = inputValues[param.name]
		const { clauseName, subClauseCode, subClauseName, name, type, label } = param
		const row = [clauseName, subClauseCode, subClauseName, name, type, label]
		switch (param.type) {
			case 'beneficial':
				const beneficial = beneficialsMap[value]
				if (beneficial) {
					spreadsheets[`BENEFICIAL-${beneficial.id}`] = serializeBeneficial(beneficial)
				}
				spreadsheets['default'].push([...row, beneficial ? `BENEFICIAL-${beneficial.id}` : ""])
				break;
			case 'beneficial[]':
				const beneficials = Array.isArray(value) ? value.map(bId => beneficialsMap[bId]) : []
				for (const beneficial of beneficials || []) {
					spreadsheets[`BENEFICIAL-${beneficial.id}`] = serializeBeneficial(beneficial)
				}
				spreadsheets['default'].push([...row, beneficials?.map(b => `BENEFICIAL-${b.id}`)?.join(", ") ?? ""])
				break;
			case 'table':
				const rows = value ?? []
				spreadsheets[`TABLE-${tableCounter}`] = [
					param.args.map(arg => arg.header),
					...rows
				]
				spreadsheets['default'].push([...row, `TABLE-${tableCounter}`])
				++tableCounter
				break;
			case 'csv':
				const [csvTransposed, csvRows, csvName] = value ?? [false, [], ""]
				spreadsheets[`TABLE-${tableCounter}`] = csvRows
				spreadsheets['default'].push([...row, `TABLE-${tableCounter}`])
				++tableCounter
				break;
			case 'file':
				if (value instanceof File) {
					spreadsheets['default'].push([...row, value.name ?? ""])
				} else {
					const fileName = fileNames[value]
					spreadsheets['default'].push([...row, fileName ?? ""])
				}
				break;
			case 'list':
				spreadsheets['default'].push([...row, value?.map(idx => idx + 1)?.join(",") ?? ""])
				break;
			case 'enum':
				const arg = param.args[value]
				spreadsheets['default'].push([...row, arg?.option ?? ""])
				break;
			case 'boolean':
				spreadsheets['default'].push([...row, (value == true && "Y") || (value == false && "N") || ""])
				break;
			case 'comment':
			case 'date':
			case 'number':
			case 'string':
			case 'property':
				spreadsheets['default'].push([...row, value ?? ""])
				break;
			default:
				break;
		}
	}
	return cellsToXLSXWorkbook(spreadsheets)

};
export function serializeBeneficial(beneficial: BeneficialEntity): string[][] {
	if (beneficial.type == 'Person') {
		return [
			['name', 'value'],
			['type', beneficial.type],
			['firstName', beneficial.firstName ?? ""],
			['lastName', beneficial.lastName ?? ""],
			['email', beneficial.email ?? ""],
			['jobTitle', beneficial.jobTitle ?? ""],
			['addressLine', beneficial.address?.addressLine ?? ""],
			['dateOfBirth', String(beneficial.dateOfBirth) ?? ""],
			['placeOfBirth', beneficial.placeOfBirth ?? ""],
			["", ""],
			["", "Signature"],
			[beneficial.fullName, ""],
		]
	} else if (beneficial.type == 'Company') {
		return [
			['name', 'value'],
			['type', beneficial.type],
			['companyName', beneficial.companyName ?? ""],
			['socialCapital', String(beneficial.socialCapital) ?? ""],
			['postalAddress', beneficial.postalAddress ?? ""],
			['legalForm', beneficial.legalForm ?? ""],
			['registrationNumber', beneficial.registrationNumber ?? ""],
			['specialization', beneficial.specialization ?? ""],
			["", ""],
			['firstName', beneficial.firstName ?? ""],
			['lastName', beneficial.lastName ?? ""],
			['email', beneficial.email ?? ""],
			['jobTitle', beneficial.jobTitle ?? ""],
			['addressLine', beneficial.address?.addressLine ?? ""],
			['dateOfBirth', String(beneficial.dateOfBirth) ?? ""],
			['placeOfBirth', beneficial.placeOfBirth ?? ""],
			["", ""],
			["", "Signature"],
			[beneficial.fullName, ""],
		]
	} else if (beneficial.type == 'Minor') {
		return [
			['name', 'value'],
			['type', beneficial.type],
			['firstName', beneficial.firstName ?? ""],
			['lastName', beneficial.lastName ?? ""],
			['email', beneficial.email ?? ""],
			['jobTitle', beneficial.jobTitle ?? ""],
			['addressLine', beneficial.address?.addressLine ?? ""],
			['dateOfBirth', String(beneficial.dateOfBirth) ?? ""],
			['placeOfBirth', beneficial.placeOfBirth ?? ""],
			["", ""],
			['minorFirstName', beneficial.minorFirstName ?? ""],
			['minorLastName', beneficial.minorLastName ?? ""],
			['minorDateOfBirth', String(beneficial.minorDateOfBirth) ?? ""],
			['minorPlaceOfBirth', beneficial.minorPlaceOfBirth ?? ""],
			['minorOrderNumber', beneficial.minorOrderNumber ?? ""],
			['minorOrderDate', String(beneficial.minorOrderDate) ?? ""],
			['minorOrderCourt', beneficial.minorOrderCourt ?? ""],
			['minorOrderName', beneficial.minorOrderName ?? ""],
			["", ""],
			["", "Signature"],
			[beneficial.fullName, ""],
			[beneficial.minorFullName, ""],
		]
	} else if (beneficial.type == 'Joint') {
		return [
			['name', 'value'],
			['type', beneficial.type],
			['firstName', beneficial.firstName ?? ""],
			['lastName', beneficial.lastName ?? ""],
			['email', beneficial.email ?? ""],
			['jobTitle', beneficial.jobTitle ?? ""],
			['addressLine', beneficial.address?.addressLine ?? ""],
			['dateOfBirth', String(beneficial.dateOfBirth) ?? ""],
			['placeOfBirth', beneficial.placeOfBirth ?? ""],
			["", ""],
			['spouseFirstName', beneficial.spouseFirstName ?? ""],
			['spouseLastName', beneficial.spouseLastName ?? ""],
			['spouseCin', beneficial.spouseCin ?? ""],
			['spouseCinDeliveryDate', String(beneficial.spouseCinDeliveryDate) ?? ""],
			['spouseCinDeliverPlace', beneficial.spouseCinDeliverPlace ?? ""],
			["", ""],
			["", "Signature"],
			[beneficial.fullName, ""],
			[beneficial.spouseFullName, ""],
		]
	} else if (beneficial.type == 'Attorney') {
		return [
			['name', 'value'],
			['type', beneficial.type],
			['firstName', beneficial.firstName ?? ""],
			['lastName', beneficial.lastName ?? ""],
			['email', beneficial.email ?? ""],
			['jobTitle', beneficial.jobTitle ?? ""],
			['addressLine', beneficial.address?.addressLine ?? ""],
			['dateOfBirth', String(beneficial.dateOfBirth) ?? ""],
			['placeOfBirth', beneficial.placeOfBirth ?? ""],
			["", ""],
			['attorneyFirstName', beneficial.attorneyFirstName ?? ""],
			['attorneyLastName', beneficial.attorneyLastName ?? ""],
			['attorneyCin', beneficial.attorneyCin ?? ""],
			['attorneyCinDeliveryDate', String(beneficial.attorneyCinDeliveryDate) ?? ""],
			['attorneyCinDeliverPlace', beneficial.attorneyCinDeliverPlace ?? ""],
			["", ""],
			["", "Signature"],
			[beneficial.attorneyFullName, ""],
		]
	}

}
export const serializeParamValuesForm = (paramValues, template, fileNames, beneficialsMap) => {
	const params = mergeParamsForm(template)

	let spreadsheets: Record<string, string[][]> = {
		"default": [
			['Page_Name', 'Name', 'Type', 'Label', 'Value'],
		],
	}
	let paramMap: Record<string, boolean> = {}
	let tableCounter = 1
	for (const param of params) {
		if (paramMap[param.name]) {
			continue
		}
		paramMap[param.name] = true
		const value = paramValues[param.name]
		const { clauseName, name, type, label } = param
		const row = [clauseName, name, type, label]
		switch (param.type) {
			case 'beneficial':
				const beneficial = beneficialsMap[value]
				if (beneficial) {
					spreadsheets[`BENEFICIAL-${beneficial.id}`] = serializeBeneficial(beneficial)
				}
				spreadsheets['default'].push([...row, beneficial ? `BENEFICIAL-${beneficial.id}` : ""])
				break;
			case 'beneficial[]':
				const beneficials = Array.isArray(value) ? value.map(bId => beneficialsMap[bId]) : []
				for (const beneficial of beneficials || []) {
					spreadsheets[`BENEFICIAL-${beneficial.id}`] = serializeBeneficial(beneficial)
				}
				spreadsheets['default'].push([...row, beneficials?.map(b => `BENEFICIAL-${b.id}`)?.join(", ") ?? ""])
				break;
			case 'table':
				const rows = value ?? []
				spreadsheets[`TABLE-${tableCounter}`] = [
					param.args && param.args.map(arg => arg.header),
					...rows
				]
				spreadsheets['default'].push([...row, `TABLE-${tableCounter}`])
				++tableCounter
				break;
			case 'csv':
				const [csvTransposed, csvRows, csvName] = value ?? [false, [], ""]
				spreadsheets[`TABLE-${tableCounter}`] = csvRows
				spreadsheets['default'].push([...row, `TABLE-${tableCounter}`])
				++tableCounter
				break;
			case 'file':
				if (value instanceof File) {
					spreadsheets['default'].push([...row, value.name ?? ""])
				} else {
					const fileName = fileNames[value]
					spreadsheets['default'].push([...row, fileName ?? ""])
				}
				break;
			case 'list':
				spreadsheets['default'].push([...row, value?.map(idx => idx + 1)?.join(",") ?? ""])
				break;
			case 'enum':
				if (param.args) {
					const arg = param.args[value]
					spreadsheets['default'].push([...row, arg?.option ?? ""])
				}
				break;
			case 'boolean':
				spreadsheets['default'].push([...row, (value == true && "Y") || (value == false && "N") || ""])
				break;
			case 'comment':
			case 'date':
			case 'number':
			case 'string':
			case 'property':
				spreadsheets['default'].push([...row, value ?? ""])
				break;
			default:
				break;
		}
	}
	return cellsToXLSXWorkbook(spreadsheets)

};
