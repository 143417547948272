import { useContext, useEffect, useRef, useState } from "react";
import InputValuesContext from "../../../contexts/InputValuesContext";
import SelectionContext from "../../../contexts/SelectionContext";
import { isSubClauseExcluded } from "../../../helpers/helper";
import { ContractTemplateParsedData, getClauseName, getSubClauseName } from "../../../helpers/ParseTemplateData";
import RenderSegmentedText from "../../../components/RenderSegmentedText";
import { useCollapse } from "react-collapsed";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import { scrollParamToTopIfNotVisible, isElementVisibleInClause, scrollToTopClause } from "../../../utils/clauseScroll";
import ClauseComparisonModal from "../../../components/modals/ClauseComparisonModal/index";
import { useTranslation } from "../../../contexts/TranslationProvider";

const { default: EditIcon } = require("../../../assets/edit_icon.svg");
const { default: EditIconOn } = require("../../../assets/edit_icon_on.svg");
const { default: CommentIcon } = require("../../../assets/comment_icon.svg");
const { default: CommentIconOn } = require("../../../assets/comment_icon_on.svg");
const { default: AiComparison } = require("../../../assets/compare-icon.svg");



function Clause({ clause, clauseIndex, clauseListRef }: { clause: ContractTemplateParsedData[number], clauseIndex: number, clauseListRef: React.MutableRefObject<any> }) {
  const {
    contractTemplate,
    inputValues,
    fileNames,
    beneficialsMap,
    segmentsOverrides,
    commentsOverrides,
    setCommentsOverrides,
    setSegmentsOverrides,
    excludedSubClauses,
    accessUser
  } = useContext(InputValuesContext);
  const {
    setSelected,
    selected,
    isClauseEditing,
    setIsClauseEditing,
  } = useContext(SelectionContext);
  const isAccessible = accessUser?.hasOwnProperty(clause.index) ? accessUser[clause.index] : false;
  const isEditable = isAccessible == true
  const isReadable = !!isAccessible
  const isSelected = selected.clauseId === clause.id
  const [isCommenting, setIsCommenting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showComparisonModal, setShowComparisonModal] = useState(false);
  const [isExpanded, setExpanded] = useState(isReadable);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const comment = commentsOverrides[clause.code] ?? clause.comment
  const { t, language } = useTranslation();


  const onCommentChange = (value: string) => {
    let newValue;
    if (value === clause.comment) {
      newValue = commentsOverrides;
      delete newValue[clause.code];
    } else {
      newValue = {
        ...commentsOverrides,
        [clause.code]: value,
      };
    }
    setCommentsOverrides(newValue);
  };
  useEffect(() => {
    setExpanded(isReadable);
    return () => {
    };
  }, [isSelected, isReadable]);


  const handleClick = (event) => {
    if (!event.defaultPrevented) {
      setSelected({
        eventType: 'Text',
        clauseId: clause.id,
        paramName: null,
      });
    }
  };
  const formattedRawRef = useRef(null);
  const clauseRef = useRef(null);
  const textareaRef = useRef(null);


  //used to handle scrolling inside clause when an input has been selected
  useEffect(() => {
    if (selected.eventType == 'ParamValueChange' && selected.paramName && clauseRef.current) {
      const elementId = `param-${selected.paramName}`
      if (!isElementVisibleInClause(elementId)) {
        scrollParamToTopIfNotVisible(elementId, clauseRef)
      }
    }
  }, [selected.paramName, clauseRef]);

  //used to handle scrolling to top inside clause when a new clause has been selected
  useEffect(() => {
    if (clauseRef.current) {
      scrollToTopClause(clauseRef)
    }
  }, [selected.clauseId, clauseListRef]);
  return (
    <div
      key={clause.id}
      className={`edit-contract-clause selectable px-4 py-2 mx-1 my-2 clause-${clause.id
        } ${isSelected && isExpanded ? "selected" : ""}`}
      onClick={handleClick}
      id={`${clause.id}`}
      ref={clauseRef}
      style={{ minHeight: isExpanded && "130px", width: isSelected && "99%" }}
    >
      <div {...getToggleProps()} className="edit-contract-clause-header">
        <b>{getClauseName(contractTemplate, clause, clauseIndex)}</b>
        <div>
          {!isExpanded ? <GoChevronDown
            style={{ marginLeft: "15px" }}
            onClick={(e) => {
              e.preventDefault()
              isReadable && setExpanded(!isExpanded);
            }} /> : <GoChevronUp
            // style={{ marginLeft: "15px", marginTop: "px" }}
            onClick={(e) => {
              e.preventDefault()
              if (isReadable) {
                setExpanded(!isExpanded);
                setIsCommenting(false);
                setIsClauseEditing(false);
              }
            }} />}


          {showComparisonModal && (
            <ClauseComparisonModal
              onClose={() => setShowComparisonModal(false)}
              currentClause={clause}
              inputValues={inputValues}
              fileNames={fileNames}
              beneficialsMap={beneficialsMap}
              segmentsOverrides={segmentsOverrides}
              excludedSubClauses={excludedSubClauses}
            />
          )}
        </div>
      </div>
      {isExpanded && (
        <div className={`edit-contract-clause-header-icons ${language == "ar" && "edit-contract-clause-header-icons-arabic"}`}>
          <img
            src={AiComparison}
            style={{ paddingLeft: language == "ar" && "10px" }}
            className="edit-contract-clause-header-icon"
            alt="#"
            onClick={() => setShowComparisonModal(true)}
          />
          <img
            src={isCommenting ? CommentIconOn : CommentIcon}
            className="edit-contract-clause-header-icon"
            alt="#"
            style={{ paddingLeft: language == "ar" && "10px" }}
            onClick={() => {
              console.log("onClick");
              setIsCommenting(!isCommenting);
            }}
          />
          <img
            src={isClauseEditing ? EditIconOn : EditIcon}
            className="edit-contract-clause-header-icon"
            alt="#"
            style={{ paddingLeft: language == "ar" && "10px" }}
            onClick={() => {
              console.log("onClick");
              setIsClauseEditing(!isClauseEditing);
            }}
          />
        </div>
      )}
      {isExpanded && isCommenting && (
        <hgroup
          className="edit-contract-clause-comment speech-bubble d-flex flex-column"
          onClick={() => !isEditing ? setIsEditing(!isEditing) : null}
        >
          {isEditing ?
            <textarea
              autoFocus
              value={comment}
              className={isEditing ? "input-comment" : ""}
              onChange={(e) => onCommentChange(e.target.value)}
              onBlur={() => setIsEditing(!isEditing)} />
            :
            <div className="comment-text">{comment}</div>
          }
          <div className="triangle"></div>
        </hgroup>
      )}
      <div {...getCollapseProps()}>
        {clause.segmentation?.segmentedText?.length > 0 && (
          <div ref={isSelected ? formattedRawRef : null}>
            <RenderSegmentedText
              clauseId={clause.id}
              segmentation={clause.segmentation}
              fileNames={fileNames}
              beneficialsMap={beneficialsMap}
              segmentsOverrides={segmentsOverrides}
              onSegmentChange={(id, text) => setSegmentsOverrides({
                ...segmentsOverrides,
                [id]: text,
              })}
              isSelected={isSelected}
              isEditing={isClauseEditing && isSelected}
              formattedRawRef={formattedRawRef}
              inputValues={inputValues}
            />
          </div>
        )}
        {clause.subClauses.map((subClause, subClauseIndex) => {
          const isExcludedSubclause = isSubClauseExcluded(
            subClause.id,
            excludedSubClauses
          );
          return (
            !isExcludedSubclause && (
              <div
                key={subClause.id}
                className={`selectable my-2 clause-${clause.id}-${subClause.id}`}
                onClick={(event) => handleClick(event)}
                id={`${clause.id}-${subClause.id}`}
              >
                <h5 className="fw-bold">{getSubClauseName(contractTemplate, clause, subClause, clauseIndex, subClauseIndex)}</h5>
                <div>
                  {" "}
                  {subClause.segmentation?.segmentedText?.length > 0 && (
                    <RenderSegmentedText
                      clauseId={clause.id}
                      segmentation={subClause.segmentation}
                      subClauseId={subClause.id}
                      fileNames={fileNames}
                      beneficialsMap={beneficialsMap}
                      segmentsOverrides={segmentsOverrides}
                      onSegmentChange={(id, text) => setSegmentsOverrides({
                        ...segmentsOverrides,
                        [id]: text,
                      })}
                      isSelected={isSelected}
                      isEditing={isClauseEditing && isSelected}
                      formattedRawRef={formattedRawRef}
                      inputValues={inputValues}
                    />
                  )}
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}

export default Clause;
