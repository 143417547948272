import { AxiosInstance } from "axios";
import { ModelClient } from "../ModelClient";
import { ContractDocxExportEntity } from "../../domain/entities/ContractDocxExport";
import { ContractExportText } from "../../domain/Contract";


export class ContractDocxExportClient extends ModelClient<ContractDocxExportEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'ContractDocxExport')
  }

  async getDefault() {
    const { rows } = await this.getAllByFilter({ isDefault: true }, 'pure')
    return rows[0] || null;
  }

  async setDefault(id: ContractDocxExportEntity['id']) {
    const reponse = await this.apiClient.post<{ row: ContractDocxExportEntity }>(`${this.modelUrl}/setDefault`, {}, {
      params: { id }
    });
    return reponse.data.row;
  }

  async dataToDocx(id: ContractDocxExportEntity['id'], data: ContractExportText) {
    const reponse = await this.apiClient.post<{ url: string }>(`${this.modelUrl}/dataToDocx`, { data }, {
      params: { id }
    });
    return reponse.data.url;
  }

};

