import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./renderSegmentedText.scss";
import {
  ClauseEntity,
  ContractEntity,
  DocumentEntity,
  SubClauseEntity,
} from "../../domain/entities";
import {
  findParamBoundaries,
  getRenderSegments,
} from "../../helpers/segmentation";
import {
  RenderSegments,
  SegmentedText,
  SegmentedTextType,
} from "../../domain/types/ClauseParams";
import { StateSync } from "../../utils/state";
import SelectionContext from "../../contexts/SelectionContext";
import TableRender from "./TableRender";
import { useTranslation } from "../../contexts/TranslationProvider";
import FilePreviewModal from "../modals/FilePreviewModal";
import { useApiClientWithLoading } from "../../services/api/ApiClient";
import { DocumentClient } from "../../services/api/DocumentClient";
import { fetchTextFileUrl } from "../../helpers/helper";
import { renderStyledSegment } from "../../helpers/renderSegment";
import RenderSingleSegment from "./RenderSingleSegment";
import RenderStaticTableSegmentedText from "./RenderStaticTableSegmentedText";

interface RenderSegmentedTextProps {
  inputValues: Record<string, any>;
  clauseId: ClauseEntity["id"];
  segmentation: ClauseEntity["segmentation"];
  subClauseId?: SubClauseEntity["id"];
  fileNames: ContractEntity["fileNames"];
  beneficialsMap: ContractEntity["beneficialsMap"];
  segmentsOverrides: Record<string, string>;
  onSegmentChange: (id: string, text: string) => void;
  isSelected: boolean;
  isEditing: boolean;
  formattedRawRef?: React.MutableRefObject<any>;
}
function RenderSegmentedText({
  segmentation,
  fileNames,
  beneficialsMap,
  segmentsOverrides,
  onSegmentChange,
  isSelected,
  isEditing,
  inputValues,
}: RenderSegmentedTextProps) {
  const { selected } = useContext(SelectionContext);

  const { t, language } = useTranslation();

  const [editingSegmentId, setEditingSegmentId] = useState(null);
  const [changedParamBoundaries, setChangedParamBoundaries] = useState<
    [number, number][]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToPreview, setFileToPreview] = useState<DocumentEntity | null>(null);
  const apiClient = useApiClientWithLoading();
  const documentClient = new DocumentClient(apiClient);
  const [text, setText] = useState<string>(null);
  const spanRef = useRef(null);
  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.contentEditable = true;
      spanRef.current.focus();
    }
  }, [editingSegmentId]);

  const stateSync = useMemo(
    () =>
      new StateSync(
        setEditingSegmentId,
        (prev, newValue) => {
          return prev || newValue;
        },
        100
      ),
    [setEditingSegmentId]
  );

  const renderSegments: RenderSegments = getRenderSegments(
    segmentation.segmentedText,
    inputValues,
    fileNames,
    beneficialsMap,
    segmentation.segmentedParams,
    t,
    language
  ).map((seg) => {
    return {
      ...seg,
      value: seg.value?.replaceAll(/\r\n/g, "\n"),
    };
  });
  const handlePreviewFile = async (id) => {
    if (id && Number(id)) {
      try {
        const row = await documentClient.getById(id);
        const text = await fetchTextFileUrl(row?.textFile);
        if (row && text) {
          setFileToPreview(row);
          setText(text);
          setIsModalOpen(true);
        }
      } catch (err) {
        if (err.response.status === 400) {
          setText("");
          setIsModalOpen(false)
        }
      }
    }
  };
  const handleStaticContentChange = (id: SegmentedText[number][0]) => (e) => {
    const newContent = e.target.innerText;
    console.log(JSON.stringify(newContent));

    const segment = renderSegments.find((seg) => seg.id == id);
    if (segment) {
      let newValue;
      if (newContent == segment.value) {
        newValue = segmentsOverrides;
        delete newValue[id];
      } else {
        newValue = {
          ...segmentsOverrides,
          [id]: newContent,
        };
      }
      stateSync.set(null);
      onSegmentChange(id, newContent);
    } else {
      console.warn(id);
    }
  };
  useEffect(() => {
    const paramNames = renderSegments
      .map((seg) => (seg as any).paramName)
      .filter((seg) => seg);
    if (
      selected.eventType == "ParamValueChange" &&
      selected.paramName &&
      paramNames.includes(selected.paramName)
    ) {
      const boundaries = findParamBoundaries(
        renderSegments,
        selected.paramName
      );
      setChangedParamBoundaries(boundaries);
    } else {
      setChangedParamBoundaries([]);
    }
  }, [selected]);
  const openFilePreview = async (id) => {
    await handlePreviewFile(id);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFileToPreview(null);
  };
  const filteredSegments = [];
  let isInTable = false; // Flag to track if we are inside a table
  renderSegments.forEach((segment, idx) => {
    (segment as any).actualIdx = idx;
    if (segment.type === SegmentedTextType.STATIC_TABLE_START) {
        // Extract segments for the table separately
        const paramName = segment.paramName;
        const tableEndIndex = renderSegments.findIndex(
            seg => seg.type === SegmentedTextType.STATIC_TABLE_END && seg.paramName === paramName
        );

        // Extract table segments
        const tableSegments = renderSegments.slice(idx, tableEndIndex + 1);
        
        // Render table component
        const tableComponent = (
            <RenderStaticTableSegmentedText
            handleStaticContentChange={handleStaticContentChange}
            tableSegments={tableSegments}
            changedParamBoundaries={changedParamBoundaries}
            isSelected={isSelected}
            isEditing={isEditing}
            editingSegmentId={editingSegmentId}
            openFilePreview={openFilePreview}
            stateSync={stateSync}
            spanRef={spanRef}
            segmentation={segmentation}
            inputValues={inputValues}
            segmentsOverrides={segmentsOverrides}
            />
        );
        filteredSegments.push(tableComponent);
        isInTable = true;
        return;
    }
    if (segment.type === SegmentedTextType.STATIC_TABLE_END) {
        isInTable = false;
        return;
    }

    // If we are not inside a table, push the segment to filteredSegments
    if (!isInTable) {
        filteredSegments.push(
            <RenderSingleSegment
            key={idx}
            segment={segment}
            isSelected={isSelected}
            isEditing={isEditing}
            editingSegmentId={editingSegmentId}
            handleStaticContentChange={handleStaticContentChange}
            openFilePreview={openFilePreview}
            changedParamBoundaries={changedParamBoundaries}
            stateSync={stateSync}
            spanRef={spanRef}
            segmentIdx={idx}
            segmentation={segmentation}
            inputValues={inputValues}
            segmentsOverrides={segmentsOverrides}
            />
        );
    }
});
  return (
    <div style={{ marginTop: "30px" }}>
      {filteredSegments}
      <FilePreviewModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        fileDocument={fileToPreview}
        text={text}
      />
    </div>
  );
}

export default RenderSegmentedText;
