import React from 'react';
import RenderSingleSegment from './RenderSingleSegment';
import { SegmentedTextType } from '../../domain/types/ClauseParams';

function RenderStaticTableSegmentedText({ handleStaticContentChange, tableSegments, changedParamBoundaries, isSelected, isEditing, editingSegmentId, openFilePreview, stateSync, spanRef, segmentation, inputValues, segmentsOverrides }) {
    const rows = [];
    let currentRow = null;
    const cells = [];
    let currentCell = null;



    tableSegments.forEach(segment => {
        const { type, id, value } = segment;

        // Start a new row
        if (type === SegmentedTextType.STATIC_TABLE_ROW_START) {
            currentRow = []; // Initialize a new row
        }
        
        // Add cells to the current row
        if (type === SegmentedTextType.STATIC_TABLE_CELL_START) {
            currentCell = []; // Initialize a new cell
        }

        // Add content to the current cell
        if (type === SegmentedTextType.STATIC 
            || type === SegmentedTextType.PARAM 
            || type === SegmentedTextType.COMMENT
            || type === SegmentedTextType.PARAM_VALUE
            
        ) {
            currentCell.push(segment);
        }

        // End the cell
        if (type === SegmentedTextType.STATIC_TABLE_CELL_END) {
            if (currentCell) {
                currentRow.push(currentCell);
                currentCell = null;
            }
        }
        
        // End the row
        if (type === SegmentedTextType.STATIC_TABLE_ROW_END) {
            if (currentRow) {
                rows.push(currentRow);
                currentRow = null;
            }
        }
    });

    return (
        <table className="segmented-table" style={{ borderCollapse: 'collapse', width: '100%' }}>
            <tbody>
                {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.map((cellSegments, cellIndex) => (
                            <td key={cellIndex} style={{ border: '1px solid black', padding: '8px' }}>
                                {cellSegments.map((segment) => (
                                  <>
                                   <RenderSingleSegment
                                        key={segment.id}
                                        segment={segment}
                                        isSelected={isSelected}
                                        changedParamBoundaries={changedParamBoundaries}
                                        isEditing={isEditing}
                                        editingSegmentId={editingSegmentId}
                                        openFilePreview={openFilePreview}
                                        handleStaticContentChange={handleStaticContentChange}
                                        stateSync={stateSync}
                                        spanRef={spanRef}
                                        segmentIdx={(segment as any).actualIdx}
                                        segmentation={segmentation}
                                        inputValues={inputValues}
                                        segmentsOverrides={segmentsOverrides}
                                    />
                                  </>
                                ))}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default RenderStaticTableSegmentedText;
